import type { DerivedFilterItem } from "@/store/feedSlice";
import { memo } from "react";
import FilterContainer from "./components/FilterContainer";

interface FilterComponentProps {
  title: string;
  filters: DerivedFilterItem[];
  filterByTags: (tags: string[]) => void;
  loading: boolean;
  sorted: boolean;
  editKnownTag?: (tags: string[]) => void;
  handleEditClick?: (tier: string) => Promise<void>;
}

function FilterComponent(props: FilterComponentProps) {
  return <FilterContainer {...props} />;
}

export default memo(FilterComponent, (prevProps, nextProps) => {
  // Check if arrays have the same length
  if (prevProps.filters.length !== nextProps.filters.length) return false;

  // Check if loading state changed
  if (prevProps.loading !== nextProps.loading) return false;

  // Check if sorted state changed
  if (prevProps.sorted !== nextProps.sorted) return false;

  // Check if title changed
  if (prevProps.title !== nextProps.title) return false;

  // Deep compare filters array for relevant changes
  const prevFiltersState = prevProps.filters.map((f) => ({
    id: f.id,
    count: f.count,
    selected: f.selected,
  }));

  const nextFiltersState = nextProps.filters.map((f) => ({
    id: f.id,
    count: f.count,
    selected: f.selected,
  }));

  return JSON.stringify(prevFiltersState) === JSON.stringify(nextFiltersState);
});

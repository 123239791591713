import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CaretDown } from "@phosphor-icons/react";
import type React from "react";

interface FilterHeaderProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  // onAddTopic: () => void;
  length: number;
}

// List of filters that should show count
const filtersWithCount = [
  "Publications",
  "Themes",
  "Top Topics",
  "People",
  "Authors",
  "Initiatives",
  "Events",
  "Topics",
] as const;

// Base container styles
const containerStyles = cn(
  // Typography
  "text-sm text-gray-800",

  // Layout
  "flex items-start gap-1 h-6 overflow-hidden",
  "min-w-[125px] pl-2",

  // Focus styles
  "ring-offset-white",
  "focus-visible:ring-offset-2 focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:outline-none",

  // Responsive
  "md:block", // Show on desktop
  "hidden", // Hide on mobile
);

// Title text styles
const titleStyles = cn(
  "inline-block min-w-[125px]",
  "overflow-hidden text-ellipsis whitespace-nowrap",
);

const titleTextStyles = "font-medium";
const countStyles = "font-normal";

// Add button styles
// const addButtonStyles = cn(
//   // Base
//   "px-2 h-6",

//   // Typography
//   "text-xs text-gray-950",

//   // Visual
//   "bg-white border border-slate-200 rounded-md shadow-sm",

//   // States
//   "hover:bg-slate-50",

//   // Position
//   "absolute top-0 right-9"
// );

const FilterHeader: React.FC<FilterHeaderProps> = ({
  title,
  isOpen,
  onToggle,
  // onAddTopic,
  length,
}) => {
  // Determine if count should be shown based on filter title
  const getTitleAndCount = () => {
    if (
      !filtersWithCount.includes(title as (typeof filtersWithCount)[number])
    ) {
      return <span className={titleTextStyles}>{title}</span>;
    }
    return (
      <>
        <span className={titleTextStyles}>{title}</span>
        <span className={countStyles}>{` (${length})`}</span>
      </>
    );
  };

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <click event exists on the button>
    <div
      // biome-ignore lint/a11y/useSemanticElements: <this is needed for the interaction>
      role="button"
      tabIndex={-1}
      onClick={onToggle}
      className={containerStyles}
    >
      <span className={titleStyles}>{getTitleAndCount()}</span>

      {/* removing to avoid complexity on filter changes for now */}
      {/* {title === "Top Topics" && (
        <Button
          type="button"
          size="sm"
          variant="ghost"
          onPress={onAddTopic}
          className={addButtonStyles}
        >
          Add
        </Button>
      )} */}

      <Button
        size="icon"
        variant="ghost"
        className="absolute -right-1 top-0 h-5 pr-3 flex items-center justify-end bg-gradient-to-r from-transparent via-white to-white"
        onPress={onToggle}
      >
        <CaretDown
          size={16}
          className={cn("transition-transform", {
            "rotate-180": isOpen,
          })}
        />
      </Button>
    </div>
  );
};

export default FilterHeader;

import type { FeedDataTermsType } from "@/data-access/news";
import useFeedStore from "@/store/useFeedStore";
import BaseNewsFeedSettingPanel from "../../NewsFeedSettingPanel";

interface EditNewsFeedPanelProps {
  name: string;
  feedTerms: FeedDataTermsType[];
  feedTags: string[];
}

export const EditNewsFeedPanel = ({
  name,
  feedTerms,
  feedTags,
}: EditNewsFeedPanelProps) => {
  const [creatingOrEditingFeed, editFeed] = useFeedStore((state) => [
    state.creatingOrEditingFeed,
    state.editFeed,
  ]);

  return (
    <BaseNewsFeedSettingPanel
      initialName={name}
      initialTerms={feedTerms}
      initialTags={feedTags}
      isLoading={creatingOrEditingFeed}
      onSubmit={editFeed}
      title="Edit Newsfeed"
      submitButtonText="Save Changes"
      loadingButtonText="Saving..."
    />
  );
};

export const AddNewsFeedPanel = () => {
  const [creatingOrEditingFeed, createFeed] = useFeedStore((state) => [
    state.creatingOrEditingFeed,
    state.createFeed,
  ]);

  return (
    <BaseNewsFeedSettingPanel
      initialName=""
      initialTerms={[]}
      initialTags={[]}
      isLoading={creatingOrEditingFeed}
      onSubmit={createFeed}
      title="Add Newsfeed"
      submitButtonText="Add Newsfeed"
      loadingButtonText="Adding..."
    />
  );
};

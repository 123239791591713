import { Skeleton } from "@/components/ui/feedback/skeleton";
import { cn } from "@/lib/utils";
import type { DerivedFilterItem } from "@/store/feedSlice";
import { memo, useCallback } from "react";
import AddNewFilter from "../filters/AddNewFilter";
import FilterHeader from "../filters/FilterHeader";
import SearchBar from "../filters/FilterSearchBar";
import ShowMoreButton from "../filters/ShowMoreButton";
import {
  MAX_HEIGHT,
  SEARCH_HEIGHT,
  useFilterHeight,
  useFilterVisibility,
} from "../hooks/useFilterHeight";
import { useFilterSorting } from "../hooks/useFilterSorting";
import { useFilterState } from "../hooks/useFilterState";
import FilterList from "./FilterList";

interface FilterContainerProps {
  title: string;
  filters: DerivedFilterItem[];
  filterByTags: (tags: string[]) => void;
  loading: boolean;
  sorted: boolean;
  editKnownTag?: (tags: string[]) => void;
  handleEditClick?: (tier: string) => Promise<void>;
}

function FilterContainer({
  title,
  filters,
  filterByTags,
  loading,
  sorted,
  editKnownTag,
  handleEditClick,
}: FilterContainerProps) {
  const {
    searchTerm,
    isOpen,
    showMore,
    displayAddTopic,
    isTransitioning,
    handleFilterChange,
    handleSearchChange,
    handleClearSearch,
    toggleOpen,
    toggleShowMore,
    toggleAddTopic,
    setIsTransitioning,
  } = useFilterState(filters, filterByTags, title);

  const filteredFilters = useFilterSorting(filters, searchTerm, sorted, title);

  const { needsShowMore, showSearch: shouldShowSearch } = useFilterVisibility(
    filteredFilters.length,
    filters.length,
    !!searchTerm,
  );

  const containerHeight = useFilterHeight({
    isOpen,
    showSearch: shouldShowSearch,
    showMore,
    needsShowMore,
    filteredCount: filteredFilters.length,
  });

  const addEditFilter = useCallback(
    (updatedValue: string, filterId?: string) => {
      if (editKnownTag) {
        const updatedFilters = filterId
          ? filters.map((fil, index) => {
              if (String(index) === filterId) return updatedValue;
              return fil.value;
            })
          : [...filters.map((fil) => fil.value), updatedValue];

        editKnownTag(updatedFilters);
      }
    },
    [editKnownTag, filters],
  );

  if (loading) {
    return (
      <div className="w-full my-3 first-of-type:mt-2 last-of-type:mb-0 pl-2">
        <Skeleton className="w-1/2 h-5 mb-1.5 bg-gray-300/65" />
        <Skeleton className="h-4 w-full max-w-[202px] bg-gray-200/45" />
        <Skeleton className="h-4 w-full max-w-[202px] bg-gray-200/45 mt-1" />
        <Skeleton className="h-4 w-full max-w-[202px] bg-gray-200/45 mt-1" />
      </div>
    );
  }

  const searchBarHeight = shouldShowSearch ? SEARCH_HEIGHT : 0;
  const listHeight = showMore
    ? MAX_HEIGHT - searchBarHeight
    : containerHeight - searchBarHeight;

  return (
    <div className="w-full my-3 first-of-type:mt-2 pl-0.5 relative">
      <FilterHeader
        title={title}
        isOpen={isOpen}
        onToggle={() => {
          setIsTransitioning(true);
          toggleOpen();
        }}
        // onAddTopic={toggleAddTopic}
        length={filters.length}
      />
      <div
        className={cn(
          "transition-all duration-200 ease-in-out overflow-hidden",
          isOpen && !isTransitioning ? "opacity-100" : "opacity-0",
        )}
        style={{
          height: isOpen ? (showMore ? MAX_HEIGHT : containerHeight) : 0,
        }}
        onTransitionEnd={() => {
          setIsTransitioning(false);
        }}
      >
        {shouldShowSearch && (
          <div className="sticky top-0 bg-white z-10">
            <SearchBar
              searchTerm={searchTerm}
              title={title}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch}
              isOpen={isOpen}
            />
          </div>
        )}

        <div
          className={cn(showMore ? "overflow-y-auto" : "overflow-hidden")}
          style={{
            height: isOpen ? listHeight : 0,
          }}
        >
          {isOpen && (
            <FilterList
              items={filteredFilters}
              isOpen={isOpen}
              handleFilterChange={handleFilterChange}
              handleEditClick={handleEditClick}
              editKnownTag={editKnownTag}
              filters={filters}
              addEditFilter={addEditFilter}
              showMore={showMore}
            />
          )}

          {needsShowMore && isOpen && (
            <ShowMoreButton showMore={showMore} onToggle={toggleShowMore} />
          )}

          {displayAddTopic && editKnownTag && (
            <AddNewFilter
              onConfirm={(newValue) => {
                toggleAddTopic();
                addEditFilter(newValue);
              }}
              onCancel={toggleAddTopic}
            />
          )}

          {filteredFilters.length === 0 && searchTerm && (
            <p className="text-sm text-slate-500 pl-2">
              No {title.toLowerCase()} found
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(FilterContainer);

import type { UpdateArticleMutationInputType } from "@/data-access/news";
import type { ILogo } from "../media/logo";
import type { IArticleContent } from "./articleContent";
import type { IArticleImage } from "./articleImage";

/**
 * @deprecated This is deprecated and should no longer be used.
 *
 * Please track the necessary functionality on the GraphQL endpoint.
 * Ensure to check the `newsfeeds.ts` file for any related updates and migrations.
 */
export type IArticleAuthor = {
  name: string;
  id: number;
};

/**
 * @deprecated This is deprecated and should no longer be used.
 *
 * Please track the necessary functionality on the GraphQL endpoint.
 * Ensure to check the `newsfeeds.ts` file for any related updates and migrations.
 */
export interface IArticle {
  id: number;
  date_published: Date | string;
  authors?: IArticleAuthor[];
  image: number;
  url: string;
  lead: string;
  extract_date: string;
  images: IArticleImage[];
  summary?: string[];
  headline?: string;
  utm_url?: string;
  publisher?: {
    id: number;
    name: string;
    url: string;
    logos: ILogo[];
  };
  summaries: IArticleContent[];
  leads: IArticleContent[];
  quotes: IArticleContent[];
  headlines: IArticleContent[];
}

/**
 * @deprecated This is deprecated and should no longer be used.
 *
 * Please track the necessary functionality on the GraphQL endpoint.
 * Ensure to check the `newsfeeds.ts` file for any related updates and migrations.
 */
export const defaultArticle: IArticle = {
  id: 0,
  date_published: "",
  image: 0,
  url: "",
  lead: "",
  extract_date: "",
  images: [],
  summaries: [],
  leads: [],
  quotes: [],
  headlines: [],
};

/**
 * @deprecated This is deprecated and should no longer be used.
 *
 * Please track the necessary functionality on the GraphQL endpoint.
 * Ensure to check the `newsfeeds.ts` file for any related updates and migrations.
 */
export type AuthorType = {
  name: string;
  id: string;
};

export type ArticleEditFn = (
  changes: {
    articleId: number;
  } & UpdateArticleMutationInputType,
) => Promise<boolean>;

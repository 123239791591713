import { Separator } from "@/components/ui/controls/separator";
import { cn } from "@/lib/utils";
import { FilterCategory, FilterDisplayNames } from "@/store/feedHelpers";
import useFeedStore, {
  useFeedName,
  useFeedTerms,
  useFilterGroups,
} from "@/store/useFeedStore";
import usePublishersStore from "@/store/usePublishersStore";
import { useCallback, useMemo } from "react";
import { Fragment } from "react";
import FilterComponent from "./FilterComponent";

const Constants = {
  PEOPLE: "People",
  ORGANIZATIONS: "Organizations",
  TOPICS: "Topics",
  EVENTS: "Events",
  INITIATIVES: "Initiatives",
  THEMES: "Themes",
  TOP_TOPICS: "Top Topics",
  GEOGRAPHIC_LOCATIONS: "Geographic Locations",
  PHYSICAL_LOCATIONS: "Physical Locations",
};

const FilterOrder = [
  FilterDisplayNames[FilterCategory.IMPACT_SCORE],
  FilterDisplayNames[FilterCategory.PROMINENCE],
  FilterDisplayNames[FilterCategory.SENTIMENT],
  FilterDisplayNames[FilterCategory.PUBLICATION_TIER],
  FilterDisplayNames[FilterCategory.SOCIAL],
  FilterDisplayNames[FilterCategory.DOMAIN_AUTHORITY],
  FilterDisplayNames[FilterCategory.READERSHIP],
  FilterDisplayNames[FilterCategory.CATEGORY],
  Constants.PEOPLE,
  FilterDisplayNames[FilterCategory.AUTHOR],
  FilterDisplayNames[FilterCategory.PUBLISHER],
  Constants.ORGANIZATIONS,
  Constants.TOPICS,
  Constants.EVENTS,
  Constants.INITIATIVES,
  Constants.THEMES,
  Constants.TOP_TOPICS,
];

const ExcludedFilters = [
  Constants.GEOGRAPHIC_LOCATIONS,
  Constants.PHYSICAL_LOCATIONS,
];

// Base layout styles for the filters container
const baseContainerStyles = cn(
  "pr-4 overflow-auto h-[calc(100vh-80px)]", // Basic container styles
  "relative border-r border-slate-100", // Border and positioning
  "min-w-full w-full", // Take up all available space in grid column
  "text-ellipsis whitespace-nowrap overflow-x-hidden",
);

// Header styles for the "Filters" text
const headerStyles = cn(
  "sticky top-0 bg-white", // Positioning
  "text-slate-500 text-xs font-medium uppercase", // Typography
  "pt-4 pl-3 pb-2", // Spacing
  "leading-tight tracking-tight", // Text properties
  "w-full z-20", // Layout
);

const FeedFilters = ({ className }: { className?: string }) => {
  const [fetchingFilters, updateSelectedFilters, editFeed] = useFeedStore(
    (state) => [
      state.fetchingFilters,
      state.updateSelectedFilters,
      state.editFeed,
    ],
  );

  const feedName = useFeedName();
  const feedTerms = useFeedTerms();
  const filterGroups = useFilterGroups();

  const orderedFilterGroups = useMemo(() => {
    const filteredGroups = filterGroups.filter(
      (group) => !ExcludedFilters.includes(group.title),
    );

    return filteredGroups.sort(
      (a, b) => FilterOrder.indexOf(a.title) - FilterOrder.indexOf(b.title),
    );
  }, [filterGroups, fetchingFilters]);

  const { setSelectedTab, setDisplayPanel } = usePublishersStore((state) => ({
    setSelectedTab: state.setSelectedTab,
    setDisplayPanel: state.setDisplayPanel,
  }));

  const handleEditClick = useCallback(
    async (tier: string) => {
      const tierOptions: { [key: string]: string } = {
        "Tier 1": "tier1",
        "Tier 2": "tier2",
        "Tier 3": "tier3",
      };

      const tierTab = tierOptions[tier] || "all";
      setSelectedTab(tierTab);
      setDisplayPanel(true);
    },
    [setSelectedTab, setDisplayPanel],
  );

  return (
    <div className={cn("scrollbar-thin", baseContainerStyles, className)}>
      <div className={headerStyles}>Filters</div>
      {orderedFilterGroups.map(({ key, title, filters, sorted }, index) => (
        <Fragment key={key}>
          <FilterComponent
            title={title}
            filters={filters}
            filterByTags={(selectedFilters) =>
              updateSelectedFilters(key, selectedFilters)
            }
            editKnownTag={
              key === Constants.TOP_TOPICS
                ? (tags: string[]) =>
                    editFeed({
                      name: feedName,
                      tags,
                      terms: feedTerms,
                    })
                : undefined
            }
            handleEditClick={
              key === FilterCategory.PUBLICATION_TIER
                ? handleEditClick
                : undefined
            }
            sorted={sorted}
            loading={fetchingFilters}
          />
          {index < orderedFilterGroups.length - 1 && (
            <Separator key={`${key}_separator`} className="w-full" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default FeedFilters;

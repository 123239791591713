import Moz from "@/assets/svg/Moz.svg?react";
import Readership from "@/assets/svg/Readership.svg?react";
import { Separator } from "@/components/ui/controls/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import type { FeedWithAugTypes } from "@/data-access/news";
import type { ReactNode } from "react";
import {
  ARTICLE_READERSHIP_DESCRIPTION,
  DOMAIN_AUTHORITY_DESCRIPTION,
} from "../../news.consts";
import BuzzsumoArticle from "../../shared/BuzzsumoArticle";
import { formatNumber } from "../../shared/utils";

type IconProps = {
  icon: ReactNode;
  url: string;
  tooltip: string;
};

const ScoreDetail: React.FC<Partial<FeedWithAugTypes>> = ({
  articleId,
  maxDomainAuthority,
  maxSocial,
  articleReadership,
}) => {
  const renderDetail = (
    iconDetail: IconProps,
    value: number | string,
    description: string,
    title: string,
  ) => (
    <TooltipProvider>
      <div className="inline-flex space-x-1 items-center text-gray-500 text-xs">
        <Tooltip>
          <TooltipTrigger className="inline-flex space-x-1 items-center text-gray-500 text-xs">
            <a href={iconDetail.url} target="_blank" rel="noreferrer">
              {iconDetail.icon}
            </a>
          </TooltipTrigger>
          <TooltipContent className="max-w-80 text-xs">
            <p>{iconDetail.tooltip}</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger className="inline-flex space-x-1 items-center text-gray-500 text-xs">
            <span>{title}:</span>
            <p className="font-bold text-gray-500">
              {typeof value === "number" ? formatNumber(value) : value}
            </p>
          </TooltipTrigger>
          <TooltipContent className="max-w-80 text-xs">
            <p>{description}</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </TooltipProvider>
  );

  return (
    <div className="space-x-2 inline-flex">
      {renderDetail(
        {
          icon: <Moz className="w-6 h-6 rounded-full" />,
          url: "https://www.moz.com",
          tooltip: "Domain Authority by Moz",
        },
        maxDomainAuthority ?? "N/A",
        DOMAIN_AUTHORITY_DESCRIPTION,
        "Domain Auth",
      )}
      <Separator orientation="vertical" className="h-4 mt-1" />
      {renderDetail(
        {
          icon: <Readership className="w-4 h-4 rounded-full" />,
          url: "https://www.similarweb.com",
          tooltip: "Readership by SimilarWeb",
        },
        articleReadership ?? "N/A",
        ARTICLE_READERSHIP_DESCRIPTION,
        "Readership",
      )}
      <Separator orientation="vertical" className="h-4 mt-1" />
      <BuzzsumoArticle articleId={articleId} score={maxSocial} />
    </div>
  );
};

export default ScoreDetail;

import { transformSummary } from "@/components/news/shared/utils";
import {
  ArrowUpRight,
  DotsThree,
  Eye,
  Link,
  Question,
  RocketLaunch,
  Trash,
} from "@phosphor-icons/react";
import { formatNumber, formattedDateToUTC } from "../shared/utils";

import CreateCampaignDialog from "@/components/campaigns/createCampaignDialog";
import ArticleImage from "@/components/news/feed/NewsImage";

import { Button } from "@/components/ui/controls/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/feedback/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/navigation/dropdown-menu";

// import ArticleMentionText from "@/components/news/article/ArticleMentionText";
import type { FeedWithAugTypes } from "@/data-access/news";
import { useFeature } from "@/lib/feature-management";
import { WarningOctagon } from "@phosphor-icons/react";
import { Separator } from "react-aria-components";
import {
  ARTICLE_READERSHIP_DESCRIPTION,
  DOMAIN_AUTHORITY_DESCRIPTION,
  ENGAGEMENT_DESCRIPTION,
} from "../news.consts";
import { ArticleBadgeRow } from "../shared/ArticleBadgeDisplay";

export type ArticleDetailPanelProps = {
  onDelete: () => void;
} & Partial<FeedWithAugTypes>;

const ArticleDetailPanel = ({
  onDelete,
  articleUrl,
  summary,
  maxScore,
  maxDomainAuthority,
  maxSocial,
  imageUrl,
  articleAuthors,
  articleLastUpdateDate,
  articlePublisher,
  articleReadership,
  headline,
  prominence,
  articleNewsCategory,
  tierLevel,
}: ArticleDetailPanelProps) => {
  const amplifyEnabled = useFeature("article-amplify-enabled", false);

  const onViewArticle = () => {
    if (articleUrl) window.open(articleUrl, "_blank");
  };

  const transformedSummary = transformSummary(summary ?? "");

  return (
    <div className="flex flex-col gap-5 -mx-6 h-screen">
      <div className="flex px-8 justify-between items-center">
        <h2>Article Summary</h2>
        <div className="flex items-center gap-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="z-10">
                <DotsThree className="h-6 w-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[150px]">
              <DropdownMenuItem
                className="text-text-default text-sm flex flex-row justify-start items-center"
                onClick={onViewArticle}
              >
                <Eye className="me-2" />
                View Article
              </DropdownMenuItem>
              <DropdownMenuSeparator className="bg-slate-200" />
              <DropdownMenuItem
                className="text-text-destructive text-sm flex flex-row justify-start items-center"
                onClick={onDelete}
              >
                <Trash className="me-2" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <div className="h-6 w-[1px] bg-slate-200" />
          <Button size="icon" variant="ghost">
            <Link />
          </Button>
          <Dialog>
            <DialogTrigger asChild>
              <Button leftIcon={<RocketLaunch />}>Amplify</Button>
            </DialogTrigger>
            {amplifyEnabled ? (
              <CreateCampaignDialog url={articleUrl || ""} />
            ) : (
              <DialogContent className="min-w-[654px]">
                <div className="flex flex-row items-center gap-5">
                  <WarningOctagon />
                  <h3>Coming Soon</h3>
                </div>
              </DialogContent>
            )}
          </Dialog>
        </div>
      </div>
      <Separator orientation="vertical" />
      <div className="flex flex-col gap-10 px-10 overflow-auto flex-grow pt-4 border-t border-slate-200">
        <div className="inline-flex items-center justify-between pb-4 border-b border-slate-200">
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start border-r border-slate-200 pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Article score</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Question />
                  </TooltipTrigger>
                  <TooltipContent>
                    Article score is a score developed by Handraise to compare
                    the quality of articles relative to the newsfeed.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 bg-blue-100 rounded w-11 aspect-square">
              <p className="text-xl font-semibold leading-7 text-blue-600">
                {maxScore}
              </p>
            </div>
          </div>
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start border-r border-slate-200 pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Domain Authority</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Question />
                  </TooltipTrigger>
                  <TooltipContent>
                    {DOMAIN_AUTHORITY_DESCRIPTION}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 bg-yellow-100 rounded w-11 aspect-square">
              <p className="text-xl font-semibold leading-7 text-yellow-900">
                {maxDomainAuthority}
              </p>
            </div>
          </div>
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start border-r border-slate-200 pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Total Engagement</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Question />
                  </TooltipTrigger>
                  <TooltipContent>{ENGAGEMENT_DESCRIPTION}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 h-11">
              <p className="text-xl font-semibold leading-7 text-slate-800">
                {maxSocial}
              </p>
            </div>
          </div>
          <div className="h-[76px] flex flex-col gap-2 justify-start items-start pr-2">
            <div className="inline-flex space-x-2 items-center">
              <div>Readership</div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Eye />
                  </TooltipTrigger>
                  <TooltipContent>
                    {ARTICLE_READERSHIP_DESCRIPTION}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="inline-flex flex-col items-center justify-center px-2 py-2 h-11">
              <p className="text-xl font-semibold leading-7 text-slate-800">
                {formatNumber(articleReadership ?? 0)}
              </p>
            </div>
          </div>
        </div>
        <div className="inline-flex flex-col gap-10 bg-white">
          <div className="flex flex-col space-y-5">
            <h2 className="text-4xl text-left font-serif leading-tight text-slate-800">
              {headline}
            </h2>
            <div className="inline-flex space-x-3 justify-center leading-5">
              <p className="text-sm font-medium leading-tight text-slate-500">
                {articlePublisher?.name || "Publication Name"}
              </p>
              <div className="h-full w-[1px] bg-slate-200" />
              <div className="inline-flex space-x-1 items-start justify-start text-sm text-slate-500">
                {articleAuthors && (
                  <p className="text-ellipsis overflow-hidden line-clamp-1">
                    {articleAuthors
                      .slice(0, 2)
                      .map((author) => author.name)
                      .join(", ")}
                    {articleAuthors.length > 2 &&
                      ` and ${articleAuthors.length - 2} more`}
                  </p>
                )}
                {articleAuthors && articleAuthors.length > 0 && (
                  <Separator orientation="vertical" className="mr-1" />
                )}
                <p className="min-w-24">
                  {formattedDateToUTC(articleLastUpdateDate)}
                </p>
              </div>
            </div>
          </div>
          <div className="self-center mb-3">
            <ArticleBadgeRow
              prominence={prominence}
              articleNewsCategory={articleNewsCategory}
              tierLevel={tierLevel}
            />
          </div>
          <div className="inline-flex gap-3 mx-auto items-center justify-center">
            {articlePublisher?.logoUrl && (
              <img
                alt={articlePublisher?.name || "Publication Name"}
                src={articlePublisher?.logoUrl}
                className="h-9 w-auto"
              />
            )}
            {articlePublisher?.logoUrl && articleUrl && (
              <div className="h-full w-[1px] bg-slate-200" />
            )}
            {articleUrl && (
              <Button
                variant="secondary"
                onClick={onViewArticle}
                rightIcon={<ArrowUpRight className="w-4 h-4" />}
              >
                View Article
              </Button>
            )}
          </div>
          {imageUrl && (
            <ArticleImage
              size="stretch"
              imageUrl={imageUrl}
              additionalClasses="self-center"
            />
          )}
          <div className="flex flex-col gap-10 mb-10">
            {summary && (
              <div className="w-[420px] mx-auto text-left flex flex-col gap-4 text-slate-900">
                <h3>Summary</h3>
                {transformedSummary.map((paragraph, index) => (
                  <p
                    key={`${index}-${paragraph}`}
                    className="text-xs tracking-wide leading-tight break-words"
                    title={summary ?? ""}
                  >
                    {paragraph}
                  </p>
                ))}
              </div>
            )}

            {/* {(article?.articleMentions ?? []).length > 0 && (
              <div className="w-[420px] mx-auto text-left flex flex-col gap-4 pb-8">
                <h3>Mentions</h3>
                <div className="relative">
                  <div
                    className="w-6 h-4 absolute bg-red-200"
                    style={{ left: 115.55, top: 0 }}
                  />
                  {article?.articleMentions?.map((mention, index) => (
                    <ArticleMentionText
                      key={index}
                      snippet={mention.snippet}
                      term={mention.term}
                      className="py-0.5"
                    />
                  ))}
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetailPanel;

import BackgroundGradientLayout from "@/components/common/Gradient";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";

interface NewsImageProps {
  image?: HTMLImageElement;
  imageUrl?: string | null;
  size?: "small" | "medium" | "large" | "stretch";
  isHovering?: boolean;
  additionalClasses?: string;
}

const sizeClasses = {
  small: "w-1/4 h-36",
  medium: "w-[310px] h-[232px]",
  large: "w-[400px] h-full",
  stretch: "w-full h-full",
};

const hoverEffect = "scale-[1.02] brightness-[1.02]";

const NewsImage: React.FC<NewsImageProps> = ({
  image,
  imageUrl,
  size = "medium",
  isHovering = false,
  additionalClasses = "",
}) => {
  const [imageLoaded, setImageLoaded] = useState(
    image ? image.complete : false,
  );
  const imgRef = useRef<HTMLImageElement>(null);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageLoaded(false);
  };

  useEffect(() => {
    if (imgRef.current?.complete) {
      setImageLoaded(true);
    }
  }, [imgRef]);

  const imageSrc = image ? image.src : imageUrl;
  const imageClasses = `w-full h-full object-cover transition-all duration-300 ease-out ${
    isHovering ? hoverEffect : ""
  } ${imageLoaded ? "opacity-100" : "opacity-0"}`;

  return (
    <div
      className={cn(
        "rounded overflow-hidden flex-none",
        sizeClasses[size],
        additionalClasses,
      )}
      style={{ position: "relative" }}
    >
      {!imageLoaded && (
        <Skeleton className="absolute top-0 left-0 w-full h-full" />
      )}
      {(imageSrc || image) && (
        // biome-ignore lint/a11y/useAltText: <explanation>
        <img
          ref={imgRef}
          key={imageSrc || image?.src}
          onLoad={handleImageLoad}
          onError={handleImageError}
          className={imageClasses}
          src={imageSrc || image?.src}
          style={{ position: "absolute", top: 0, left: 0 }}
        />
      )}
      {!imageUrl && !image && (
        <BackgroundGradientLayout
          className={`rounded overflow-hidden flex-none p-4 ${sizeClasses[size]} items-center justify-center flex ${additionalClasses}`}
        >
          <span className="text-black text-center text-xl">
            Image is currently being prepared, please check back soon.
          </span>
        </BackgroundGradientLayout>
      )}
    </div>
  );
};

export default NewsImage;

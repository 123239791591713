import { useMemo } from "react";
import { ITEMS_TO_SHOW, MIN_ITEMS_FOR_SEARCH } from "./useFilterState";

export const ITEM_SIZE = 26;
export const MAX_HEIGHT = 357;
export const SEARCH_HEIGHT = 30;
export const SHOW_MORE_HEIGHT = 28;

interface HeightParams {
  isOpen: boolean;
  showSearch: boolean;
  showMore: boolean;
  needsShowMore: boolean;
  filteredCount: number;
}

export function useFilterHeight({
  isOpen,
  showSearch,
  showMore,
  needsShowMore,
  filteredCount,
}: HeightParams) {
  return useMemo(() => {
    if (!isOpen) return 0;

    const searchBarHeight = showSearch ? SEARCH_HEIGHT : 0;
    const showMoreHeight = needsShowMore ? SHOW_MORE_HEIGHT : 0;

    if (!showMore) {
      // For collapsed state, show initial items plus search bar and show more button
      const itemsHeight = Math.min(filteredCount, ITEMS_TO_SHOW) * ITEM_SIZE;
      return searchBarHeight + itemsHeight + showMoreHeight;
    }

    // When expanded, return the search bar height only - container will handle the rest
    return searchBarHeight;
  }, [isOpen, showSearch, showMore, needsShowMore, filteredCount]);
}

export function useFilterVisibility(
  filteredCount: number,
  totalCount: number,
  hasActiveSearch = false,
) {
  return useMemo(() => {
    // Show "Show More" if we have more than ITEMS_TO_SHOW items
    const needsShowMore = filteredCount > ITEMS_TO_SHOW;

    // Show search if total count is above threshold OR if there's an active search
    const showSearch = totalCount > MIN_ITEMS_FOR_SEARCH || hasActiveSearch;

    return {
      needsShowMore,
      showSearch,
    };
  }, [filteredCount, totalCount, hasActiveSearch]);
}

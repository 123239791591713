import {
  type NewsFeedPublisherType,
  NewsFeedPublishers,
  type NewsFeedPublishersDataType,
} from "@/data-access/news";
import { useEffect, useState } from "react";
import { useQuery } from "urql";

interface UsePublisherSearchReturn {
  publishers: NewsFeedPublishersDataType;
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  addPublisherToSearch: (publishers: NewsFeedPublisherType[]) => void;
  handleSearch: (term: string) => void;
}

export const usePublisherSearch = (): UsePublisherSearchReturn => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCount, setSearchCount] = useState(50);
  const [publishers, setPublishers] = useState<NewsFeedPublishersDataType>([]);

  const [{ data: publisherData, fetching: isLoading }] = useQuery({
    query: NewsFeedPublishers,
    variables: {
      pubLimit: searchCount,
      pubFilter: searchTerm,
    },
  });

  const addPublisherToSearch = (newPublishers: NewsFeedPublisherType[]) => {
    setPublishers((prevPublishers) =>
      [...prevPublishers, ...newPublishers].reduce((acc, publisher) => {
        if (!acc.find((p) => p.id === publisher.id)) {
          acc.push(publisher);
        }
        return acc;
      }, [] as NewsFeedPublishersDataType),
    );
  };

  useEffect(() => {
    const publishers = publisherData?.publishers;
    if (!publishers) return;
    addPublisherToSearch(publishers);
  }, [publisherData]);

  const handleSearch = (term: string) => {
    setSearchCount(term ? 10 : 50);
    setSearchTerm(term);
  };

  return {
    publishers,
    isLoading,
    searchTerm,
    setSearchTerm,
    addPublisherToSearch,
    handleSearch,
  };
};

import { useParams } from "react-router-dom";
import { useMutation } from "urql";

// @Components
import { toast } from "@/components/ui/toast";

// @Mutation
import { deleteFeedArticleMutation } from "@/data-access/news";

// @State Slice
import useArticleDeletionStore from "@/store/articleDeleteSlice";
import useFeedStore from "@/store/useFeedStore.ts";

export const useDeleteFeedArticle = () => {
  const [articleIdsToDelete, feedIdToDeleteFromNewsFeed] =
    useArticleDeletionStore((state) => [
      state.articleIdsToDelete,
      state.feedIdToDeleteFromNewsFeed,
    ]);
  const [, removeArticle] = useMutation(deleteFeedArticleMutation);
  const [removeArticlesFromFeed] = useFeedStore((state) => [
    state.removeArticles,
  ]);
  const { feedId } = useParams();

  const handleDeleteArticle = async (reason: string) => {
    if (!feedId && !feedIdToDeleteFromNewsFeed) return;

    try {
      const { error } = await removeArticle({
        input: {
          feedId: feedId || `${feedIdToDeleteFromNewsFeed}`,
          feedArticleId: articleIdsToDelete.map((id) => id.toString()),
          reason: reason,
        },
      });

      if (!error) {
        removeArticlesFromFeed(articleIdsToDelete);
        toast({
          type: "neutral",
          title: "Articles successfully deleted",
          description: `${articleIdsToDelete.length} article(s) have been removed from your newsfeed.`,
        });
      } else {
        toast({
          type: "error",
          title: "Unable to delete articles",
          description: "0 articles were deleted from your newsfeed.",
        });
      }
    } catch (error) {
      toast({
        type: "error",
        title: "Unable to delete articles",
        description: "0 articles were deleted from your newsfeed.",
      });
    }
  };

  return { handleDeleteArticle };
};

import { isDevelopment } from "@/config";
import type { FeedDataTermsType, FeedWithAugTypes } from "@/data-access/news";
import { FilterCategory, isWithinRange } from "@/store/feedHelpers";
import { derive } from "derive-zustand";
import { create, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import {
  type DerivedFilterItem,
  type FeedSlice,
  createFeedSlice,
} from "./feedSlice";

const useFeedStore = create<FeedSlice>()(
  devtools((...a) => ({ ...createFeedSlice(...a) }), {
    name: "feedStore",
    enabled: isDevelopment,
    store: "feed",
  }),
);

export default useFeedStore;

const deriveArticleCount = derive<number>((get) => {
  const { feedItems, removalArr } = get(useFeedStore);
  return feedItems.length - removalArr.length;
});

const deriveFilterOrSearch = derive<boolean>((get) => {
  const { categoryAppliedFilters, searchTerms } = get(useFeedStore);
  return (
    (categoryAppliedFilters
      ? Object.values(categoryAppliedFilters).some(
          (filter) => (filter?.selected?.length ?? 0) > 0,
        )
      : false) || searchTerms.length > 0
  );
});

const deriveFilteredFeedItems = derive<Partial<FeedWithAugTypes>[]>((get) => {
  const {
    filterFeedIdSearch,
    feedItems,
    removalArr,
    sortOrder,
    feedVolumeSelectedDate,
  } = get(useFeedStore);

  const isFilteredOrSearched = get(deriveFilterOrSearch);

  // Create Sets for O(1) lookups
  const removalSet = new Set(removalArr);
  const filterFeedIdMap = new Map<number, { mention: string; term: string }>(
    filterFeedIdSearch as [number, { mention: string; term: string }][],
  );

  // Helper function to filter items based on removal set
  const filterOutRemovedItems = (items: Partial<FeedWithAugTypes>[]) =>
    items.filter((item) => !removalSet.has(item.id ?? -1));

  // Helper function to sort items based on sortOrder
  const sortItems = (
    a: Partial<FeedWithAugTypes>,
    b: Partial<FeedWithAugTypes>,
  ): number => {
    switch (sortOrder) {
      case "SCORE_DESC":
        return (b.maxScore || 0) - (a.maxScore || 0);
      case "SCORE_ASC":
        return (a.maxScore || 0) - (b.maxScore || 0);
      case "DATE_DESC":
      case "DATE_ASC": {
        const dateA = new Date(a.articleLastUpdateDate || 0).getTime();
        const dateB = new Date(b.articleLastUpdateDate || 0).getTime();
        return sortOrder === "DATE_DESC" ? dateB - dateA : dateA - dateB;
      }
      default:
        return 0;
    }
  };

  // If filtered or searched, transform items accordingly
  const filteredOrSearchedItems = isFilteredOrSearched
    ? Array.from(filterFeedIdMap.entries())
        .filter(([id]) => !removalSet.has(id))
        .map(([id, searchHit]) => {
          const item = feedItems.find((fi) => fi.id === id);
          return {
            ...(item ?? {}),
            searchHit,
          } satisfies Partial<FeedWithAugTypes>;
        })
    : filterOutRemovedItems(feedItems ?? []);

  const finalFilteredItems = feedVolumeSelectedDate
    ? filteredOrSearchedItems.filter((item) =>
        isWithinRange(
          item.articleLastUpdateDate,
          feedVolumeSelectedDate.startDate,
          feedVolumeSelectedDate.endDate,
        ),
      )
    : filteredOrSearchedItems;

  return finalFilteredItems.sort(sortItems);
});

const deriveFilterGroups = derive<
  {
    key: string;
    title: string;
    filters: DerivedFilterItem[];
    sorted: boolean;
  }[]
>((get) => {
  const { categoryAppliedFilters, removalArr, filterFeedIdSearch } =
    get(useFeedStore);
  if (!categoryAppliedFilters) return [];

  // Create Sets for O(1) lookups
  const filterIdsSet = new Set(filterFeedIdSearch.map(([id]) => id));
  const removalSet = new Set(removalArr);

  // Memoize the expensive operations using a WeakMap
  const countCache = new WeakMap<any[], number>();

  return Object.entries(categoryAppliedFilters).map(
    ([categoryKey, categoryValue]) => {
      const counts = categoryValue?.count ?? categoryValue?.itemCounts ?? {};
      const selectedSet = new Set(categoryValue.selected);

      let filters = Object.entries(counts).map(([filter, count]) => {
        const isArrayCount = Array.isArray(count);
        const totalCount = isArrayCount ? count.length : (count as number);

        let filteredCount = count;
        if (isArrayCount) {
          // Check cache first
          if (!countCache.has(count)) {
            filteredCount = count.reduce(
              (acc, id) =>
                !removalSet.has(id) && filterIdsSet.has(id) ? acc + 1 : acc,
              0,
            );
            countCache.set(count, filteredCount);
          } else {
            filteredCount = countCache.get(count) ?? 0;
          }
        }

        return {
          id: categoryKey + filter,
          value: filter,
          totalCount,
          count: filteredCount,
          selected: selectedSet.has(filter),
        };
      });

      // Only sort if necessary and cache the parsed numbers
      if (categoryKey === FilterCategory.SOCIAL) {
        const numberCache = new Map<string, number>();

        const getNumber = (value: string) => {
          if (!numberCache.has(value)) {
            numberCache.set(
              value,
              Number.parseInt(value.split("-")[0]?.replace(",", "") ?? "0"),
            );
          }
          const cachedValue = numberCache.get(value);
          return cachedValue !== undefined ? cachedValue : 0;
        };

        filters = filters.sort((a, b) => {
          if (a?.value === "0") return 1;
          if (b?.value === "0") return -1;
          return getNumber(b.value) - getNumber(a.value);
        });
      }

      return {
        key: categoryKey,
        title: categoryValue.name ?? categoryKey,
        filters,
        sorted: categoryValue.sorted ?? true,
      };
    },
  );
});

const deriveFeedName = derive<string>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? (currentFeed.name ?? "") : "";
});

const deriveFeedTerms = derive<FeedDataTermsType[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? (currentFeed.terms ?? []) : [];
});

const deriveKnownTags = derive<string[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed
    ? (currentFeed.knownTags?.map((tag) => tag.tag) ?? [])
    : [];
});

/**
 * Derives feed-related percentage based on the current feed state.
 * @param {function} get - Function to retrieve state slices from the store.
 * @returns {number | null} Percentage of filtered stories relative to total stories.
 */
const deriveFeedPercentage = derive<number | null>((get) => {
  const { feedItems, removalArr } = get(useFeedStore);
  const filteredFeedItemsLength = get(deriveFilteredItemsLength);

  if (!feedItems) {
    return null;
  }

  const totalStories = feedItems.length - removalArr.length;

  return totalStories > 0
    ? Math.round((filteredFeedItemsLength / totalStories) * 100)
    : null;
});

const deriveArticleIds = derive<number[]>((get) => {
  const { feedItems } = get(useFeedStore);
  return feedItems
    .map((item) => item.id)
    .filter((id): id is number => id !== undefined);
});

const deriveFilteredItemsLength = derive<number>((get) => {
  const filteredItems = get(deriveFilteredFeedItems);

  return filteredItems.length;
});

// Hooks
const useIsFilterOrSearch = () => useStore(deriveFilterOrSearch);
const useFeedPercentage = () => useStore(deriveFeedPercentage);
const useFeedName = () => useStore(deriveFeedName);
const useFeedTerms = () => useStore(deriveFeedTerms);
const useKnownTags = () => useStore(deriveKnownTags);
const useArticleCount = () => useStore(deriveArticleCount);
const useFilteredFeedItems = () => useStore(deriveFilteredFeedItems);
const useFilterGroups = () => useStore(deriveFilterGroups);
const useArticleIds = () => useStore(deriveArticleIds);
const useFilteredItemsLength = () => useStore(deriveFilteredItemsLength);

export {
  useIsFilterOrSearch,
  useFeedPercentage,
  useFeedName,
  useFeedTerms,
  useKnownTags,
  useArticleCount,
  useFilteredFeedItems,
  useFilterGroups,
  useArticleIds,
  useFilteredItemsLength,
};

import { FeedContentLayout } from "@/components/layouts/FeedContentLayout";
import FeedArticle from "@/components/news/article/FeedArticle";
import FeedHeader from "@/components/news/feed/FeedHeader";
import FeedSheets from "@/components/news/shared/FeedSheets";
import { Button } from "@/components/ui/button";
import type { FeedWithAugTypes } from "@/data-access/news";
import { CategoryQuery } from "@/data-access/news/categoryQuery";
import useFeedStore from "@/store/useFeedStore";
import { ArrowLeft } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "urql";
import { useFeedSingleArticleData } from "./hooks/useFeedSingleArticleData";

const ArticlePreviewPage: React.FC = () => {
  const { feedId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { article, data } = useFeedSingleArticleData();
  const [staleScore, updateArticle, setTierLevel] = useFeedStore((state) => [
    state.refreshingScore,
    state.updateArticle,
    state.setTierLevel,
  ]);
  const [{ data: categoryData }] = useQuery({ query: CategoryQuery });
  const preloadedImageRef = useRef<HTMLImageElement | undefined>(undefined);

  const navigateBackToNewsfeed = () => {
    if (searchParams.get("from") === "add-article-url")
      window.location.href = `/newsfeeds/${feedId}`;
    else navigate(`/newsfeeds/${feedId}`);
  };

  const preloadImage = async (src: string) => {
    const img = new Image();
    img.loading = "eager";
    img.src = src;
    preloadedImageRef.current = img;
  };

  const [augmentedArticle, setAugmentedArticle] =
    useState<Partial<FeedWithAugTypes>>(article);

  useEffect(() => {
    setAugmentedArticle(article);
  }, [article]);

  useEffect(() => {
    if (article?.imageUrl) preloadImage(article.imageUrl);
  }, [article?.imageUrl]);

  useEffect(() => {
    if (data?.tierConfiguration) {
      const augmented = setTierLevel(article, data);
      setAugmentedArticle(augmented);
    }
  }, [augmentedArticle, data, setTierLevel]);

  return (
    <>
      <FeedContentLayout>
        <FeedHeader />
        {augmentedArticle && (
          <main className="mx-auto">
            <div className="w-[900px] pt-14">
              <Button
                variant="link"
                size="none"
                className="mb-8"
                onPress={navigateBackToNewsfeed}
              >
                <ArrowLeft />
                Back to newsfeed
              </Button>
              <FeedArticle
                categories={categoryData?.categories ?? []}
                onChange={updateArticle}
                image={preloadedImageRef.current}
                staleScore={staleScore}
                article={augmentedArticle}
                isPreview={true}
              />
            </div>
          </main>
        )}
      </FeedContentLayout>
      <FeedSheets />
    </>
  );
};

export default ArticlePreviewPage;

import { graphql } from "../../graphql";

export const CREATE_ARTICLE_SUBSCRIPTION = graphql(`
  subscription CreateArticleSubscription($articleUrl: String!, $feedId: ID!) {
    articleCreation(articleUrl: $articleUrl, feedId: $feedId) {
      status
      feedArticle {
        id
      }
      uiMessage {
        type
        message
      }
    }
  }
`);

import {
  type FilterMap,
  getFilterParamsFromUrl,
  setFilterParamsToUrl,
} from "@/components/news/shared/utils";
import {
  type FeedType,
  type FeedWithAugTypes,
  type FilterSideBarCategoryCount,
  NewsFeedsSearchQuery,
} from "@/data-access/news";
import { client } from "@/lib/urqlProvider";
import { capitalizeFirstLetter, getPrettyName } from "@/lib/utils/prettyName";
import {
  differenceInDays,
  isSameDay,
  isWithinInterval,
  parseISO,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const TIER_1 = "Tier 1";
export const TIER_2 = "Tier 2";
export const TIER_3 = "Tier 3";

export type FilterValues = {
  name?: string;
  selected: string[];
  relationship: "AND" | "OR" | "OR-ONLY";
  itemCounts: Record<string, number[]>;
  count?: Record<string, number>;
  sorted?: boolean;
};

export enum FilterCategory {
  IMPACT_SCORE = "IMPACT_SCORE",
  PROMINENCE = "PROMINENCE",
  PUBLICATION_TIER = "Publication Tier",
  SENTIMENT = "SENTIMENT",
  SOCIAL = "SOCIAL",
  READERSHIP = "READERSHIP",
  DOMAIN_AUTHORITY = "DOMAIN_AUTHORITY",
  CATEGORY = "CATEGORY",
  TOP_TOPICS = "Top Topics",
  PUBLISHER = "PUBLISHER",
  AUTHOR = "AUTHOR",
}

export const FilterDisplayNames: Record<FilterCategory, string> = {
  [FilterCategory.IMPACT_SCORE]: "Impact Score",
  [FilterCategory.PROMINENCE]: "Prominence",
  [FilterCategory.PUBLICATION_TIER]: "Publication Tier",
  [FilterCategory.SENTIMENT]: "AI Sentiment",
  [FilterCategory.SOCIAL]: "Social Engagement",
  [FilterCategory.READERSHIP]: "Readership",
  [FilterCategory.DOMAIN_AUTHORITY]: "Domain Authority",
  [FilterCategory.CATEGORY]: "Content Category",
  [FilterCategory.TOP_TOPICS]: "Top Topics",
  [FilterCategory.PUBLISHER]: "Publications",
  [FilterCategory.AUTHOR]: "Authors",
};

export type CategoryFilter = Partial<FilterValues>;

export type CategoryAppliedFilter = Record<string, CategoryFilter>;

export interface FilterData {
  selectedOptions: string[];
  title: string;
  categoryKey: string;
}

export async function filterFeedItems(
  feedItems: Partial<FeedType>[],
  searchTerms: string,
  categoryAppliedFilters: CategoryAppliedFilter,
  feedId?: number,
): Promise<
  [
    number,
    searchHit?: {
      mention: string;
      term: string;
    },
  ][]
> {
  if (shouldReturnOriginalFeed(searchTerms, categoryAppliedFilters)) {
    return Promise.resolve(
      feedItems.map((item) => [item.id ?? 0]) as [
        number,
        searchHit?: {
          mention: string;
          term: string;
        },
      ][],
    );
  }

  const itemdIds = feedItems
    .map((item) => item.articleId)
    .filter((id): id is number => id !== undefined);

  const canSearchRemote = searchTerms.length && feedId;

  const foundSearchHits = canSearchRemote
    ? await fetchSearchData(feedId, searchTerms, itemdIds)
    : false;

  return filterFeedItemsBySearchAndCategory(
    feedItems,
    foundSearchHits,
    categoryAppliedFilters,
    searchTerms,
  );
}

function shouldReturnOriginalFeed(
  searchTerms: string,
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean {
  return !searchTerms.length && !hasAppliedFilters(categoryAppliedFilters);
}

function hasAppliedFilters(
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean {
  return Object.keys(categoryAppliedFilters).some(
    (key) => (categoryAppliedFilters[key]?.selected?.length ?? 0) > 0,
  );
}

async function fetchSearchData(
  feedId: number,
  searchTerm: string,
  selectedFeedItems: number[],
): Promise<Record<string, string>> {
  const minLengthForSearch = 3;

  if (searchTerm?.length < minLengthForSearch) {
    return {};
  }

  const response = await client
    .query(NewsFeedsSearchQuery, {
      feedId,
      searchTerm,
      selectedItems: selectedFeedItems,
    })
    .toPromise();

  if (!response.data) {
    console.error("Issue with GraphQL response");
    return {};
  }

  return response.data.feedSearch.items.reduce(
    (acc, item) => {
      const { id, field } = item;
      const firstContent = field[0]?.content[0];
      if (!firstContent) return acc;
      acc[`${id}`] = firstContent;
      return acc;
    },
    {} as Record<string, string>,
  );
}

const getExcludedAndIncludedTerms = (
  searchTerms: string[],
): {
  includedTerms: string[];
  excludedTerms: string[];
} => {
  const includedTerms: string[] = [];
  const excludedTerms: string[] = [];

  for (const term of searchTerms) {
    if (term.startsWith("-")) {
      excludedTerms.push(term.slice(1));
    } else {
      includedTerms.push(term);
    }
  }

  return { includedTerms, excludedTerms };
};

const getItemSearchHitString = (
  item: Partial<FeedType>,
  foundSearchHits: Record<string, string>,
): { mention: string; term: string } | null => {
  if (!foundSearchHits) return null;

  const itemId = item.id ?? -1;
  const searchHitString = foundSearchHits[`${itemId}`];

  if (!searchHitString) return null;

  const emRegex = /<em><b>(.*?)<\/b><\/em>/g;
  const match = searchHitString.match(emRegex);
  const mention = searchHitString.replace(emRegex, "$1");
  const term = match ? match[0].replace(/<.*?>/g, "") : "";

  return { mention, term };
};

function filterFeedItemsBySearchAndCategory(
  feedItems: Partial<FeedType>[],
  foundSearchHits: Record<string, string> | false,
  categoryAppliedFilters: CategoryAppliedFilter,
  searchTerm: string,
): [
  number,
  searchHit?: {
    mention: string;
    term: string;
  },
][] {
  if (!feedItems || feedItems.length === 0) {
    return [];
  }

  const searchTerms = searchTerm.split(/\s+/);
  const { includedTerms, excludedTerms } =
    getExcludedAndIncludedTerms(searchTerms);
  const results = feedItems
    .filter((item) => {
      if (!item?.id) return false;
      const remoteSearchMatch =
        foundSearchHits &&
        Object.keys(foundSearchHits).includes(`${item.id ?? -1}`);
      const localSearchMatch = matchesSearchTerms(item, includedTerms);
      const excludeMatch = excludesTerms(item, excludedTerms);
      const filterMatch = matchesSelectedFilters(item, categoryAppliedFilters);

      return (
        (remoteSearchMatch || localSearchMatch) && filterMatch && !excludeMatch
      );
    })
    .map((item) => {
      const id = item.id as number;
      const searchHit = foundSearchHits
        ? getItemSearchHitString(item, foundSearchHits)
        : undefined;
      return [id, searchHit] as [
        number,
        searchHit?: { mention: string; term: string } | undefined,
      ];
    });

  return results;
}

export function processText(item: Partial<FeedType>): string {
  const { headline, summary, articleUrl } = item;

  const normalize = (text: string | null | undefined = "") =>
    text?.toLowerCase().replace(/[`‘’“”"']/g, "'") || "";

  const textToSearch = [headline, summary, articleUrl]
    .flat()
    .map(normalize)
    .join(" ");

  return `${textToSearch}`;
}

function excludesTerms(item: Partial<FeedType>, excludeTerms: string[]) {
  const fullTextToSearch = processText(item);
  return excludeTerms.some((term) => fullTextToSearch.includes(term));
}

export function matchesSearchTerms(
  item: Partial<FeedType>,
  searchTerms: string[],
) {
  const fullTextToSearch = processText(item);
  return searchTerms.every((term) => fullTextToSearch.includes(term));
}

export const getIntersection = (
  itemCounts: Record<string, number[]>,
  selected: string[],
  relationship?: string,
): number[] => {
  if (!selected.length) return [];

  let acc: Set<number> = new Set();

  for (const [tier, ids] of Object.entries(itemCounts)) {
    if (selected.includes(tier)) {
      const idSet = new Set(ids);
      if (relationship === "AND") {
        if (acc.size === 0) {
          acc = idSet;
        } else {
          acc = new Set([...acc].filter((id) => idSet.has(id)));
        }
      } else {
        for (const id of idSet) {
          acc.add(id);
        }
      }
    }
  }

  return Array.from(acc);
};

export const matchesSelectedFilters = (
  item: Partial<FeedType>,
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean => {
  return Object.values(categoryAppliedFilters).every((filterValues) => {
    const { selected, itemCounts, relationship } = filterValues;

    if (!selected?.length || !itemCounts) {
      return true;
    }

    const ids = getIntersection(itemCounts, selected, relationship);

    return ids.includes(item.id ?? 0);
  });
};

export const initCategoryAppliedFilters = (
  filterParams: FilterMap = getFilterParamsFromUrl(),
) => {
  const filters: CategoryAppliedFilter = {
    [FilterCategory.IMPACT_SCORE]: {
      name: FilterDisplayNames[FilterCategory.IMPACT_SCORE],
      selected:
        filterParams[FilterDisplayNames[FilterCategory.IMPACT_SCORE]] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        "80 to 100": [],
        "60 to 79": [],
        "40 to 59": [],
        "20 to 39": [],
        "0 to 19": [],
        "-1 to -19": [],
        "-20 to -39": [],
        "-40 to -59": [],
        "-60 to -79": [],
        "-80 to -100": [],
      },
      sorted: false,
    },
    [FilterCategory.PROMINENCE]: {
      name: FilterDisplayNames[FilterCategory.PROMINENCE],
      selected:
        filterParams[FilterDisplayNames[FilterCategory.PROMINENCE]] || [],
      relationship: "OR",
      itemCounts: {
        Headline: [],
        Feature: [],
        Lede: [],
        "Passing Mention": [],
      },
      sorted: false,
    },
    [FilterCategory.PUBLICATION_TIER]: {
      selected:
        filterParams[FilterDisplayNames[FilterCategory.PUBLICATION_TIER]] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        [TIER_1]: [],
        [TIER_2]: [],
        [TIER_3]: [],
      },
      sorted: false,
    },
    [FilterCategory.SENTIMENT]: {
      name: FilterDisplayNames[FilterCategory.SENTIMENT],
      selected:
        filterParams[FilterDisplayNames[FilterCategory.SENTIMENT]] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        Positive: [],
        Neutral: [],
        Negative: [],
      },
      sorted: false,
    },
    [FilterCategory.SOCIAL]: {
      name: FilterDisplayNames[FilterCategory.SOCIAL],
      selected: filterParams[FilterDisplayNames[FilterCategory.SOCIAL]] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        "10,000+": [],
        "5,000-9,999": [],
        "3,000-4,999": [],
        "1,000-2,999": [],
        "500-999": [],
        "100-499": [],
        "1-99": [],
        "0": [],
      },
      sorted: false,
    },
    [FilterCategory.READERSHIP]: {
      name: FilterDisplayNames[FilterCategory.READERSHIP],
      selected:
        filterParams[FilterDisplayNames[FilterCategory.READERSHIP]] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        "10,000,001+": [],
        "5,000,001-10,000,000": [],
        "1,000,001-5,000,000": [],
        "500,001-1,000,000": [],
        "250,001-500,000": [],
        "100,001-250,000": [],
        "50,001-100,000": [],
        "10,001-50,000": [],
        "1-10,000": [],
      },
      sorted: false,
    },
    [FilterCategory.DOMAIN_AUTHORITY]: {
      name: FilterDisplayNames[FilterCategory.DOMAIN_AUTHORITY],
      selected:
        filterParams[FilterDisplayNames[FilterCategory.DOMAIN_AUTHORITY]] || [],
      relationship: "OR-ONLY",
      itemCounts: Array.from({ length: 10 }, (_, i): [string, number[]] => [
        `${90 - i * 10}-${100 - (i === 0 ? 0 : i * 10 + 1)}`,
        [],
      ]).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, number[]>,
      ),
      sorted: false,
    },
    [FilterCategory.CATEGORY]: {
      name: FilterDisplayNames[FilterCategory.CATEGORY],
      selected: filterParams[FilterDisplayNames[FilterCategory.CATEGORY]] || [],
      relationship: "OR-ONLY",
      itemCounts: {},
    },
    [FilterCategory.TOP_TOPICS]: {
      selected:
        filterParams[FilterDisplayNames[FilterCategory.TOP_TOPICS]] || [],
      relationship: "OR",
      itemCounts: {},
    },
    [FilterCategory.PUBLISHER]: {
      name: FilterDisplayNames[FilterCategory.PUBLISHER],
      selected:
        filterParams[FilterDisplayNames[FilterCategory.PUBLISHER]] || [],
      relationship: "OR-ONLY",
      itemCounts: {},
    },
    [FilterCategory.AUTHOR]: {
      name: FilterDisplayNames[FilterCategory.AUTHOR],
      selected: filterParams[FilterDisplayNames[FilterCategory.AUTHOR]] || [],
      relationship: "OR-ONLY",
      itemCounts: {},
    },
  };

  return filters;
};

export const setCounts = (
  categoryCounts: FilterSideBarCategoryCount[],
  categoryAppliedFilters: CategoryAppliedFilter,
) => {
  for (const categoryCount of categoryCounts) {
    const { category, items } = categoryCount;

    // Only process if the category exists in categoryAppliedFilters
    if (categoryAppliedFilters[category]) {
      const categoryFilter = categoryAppliedFilters[category];

      // Create a count record from items
      const counts: Record<string, number> = {};
      for (const item of items) {
        counts[item.label] = item.count;
      }

      // Copy the existing itemCounts structure and add the count numbers
      categoryFilter.count = counts;
    }
  }
};

export const updateCountsForCoverageTiers = (
  feedItem: Partial<FeedWithAugTypes>,
  coverageTier: CategoryFilter,
) => {
  const { id, tierLevel } = feedItem;
  if (!tierLevel || !id) return;

  updateItemCount(coverageTier, tierLevel, id);
};

export const updateCountsForSentiment = (
  feedItem: Partial<FeedWithAugTypes>,
  sentimentFilter: CategoryFilter,
) => {
  const { id, articleSentiment } = feedItem;
  if (!articleSentiment || !id) return;

  updateItemCount(
    sentimentFilter,
    capitalizeFirstLetter(articleSentiment.polarity),
    id,
  );
};

export const updateCountsForPublisher = (
  feedItem: Partial<FeedType>,
  publisherFilter: CategoryFilter,
) => {
  const { id, articlePublisher } = feedItem;
  const publisher = articlePublisher?.name;
  if (!id || !publisher) {
    return;
  }

  if (publisher) updateItemCount(publisherFilter, publisher, id);
};

export const updateCountsForAuthors = (
  feedItem: Partial<FeedType>,
  authorFilter: CategoryFilter,
) => {
  const { id, articleAuthors } = feedItem;
  if (!id || !articleAuthors?.length) return;

  if (!authorFilter) {
    return;
  }

  for (const item of articleAuthors) {
    const { name } = item;
    updateItemCount(authorFilter, name, id);
  }
};

export const updateCountsForCategory = (
  feedItem: Partial<FeedType>,
  categoryFilter: CategoryFilter,
) => {
  const { id, articleNewsCategory } = feedItem;
  if (!id || !articleNewsCategory?.length) return;
  const articleCategory = getPrettyName(articleNewsCategory);

  if (!categoryFilter) {
    return;
  }

  updateItemCount(categoryFilter, articleCategory, id);
};

export const updateCountsForArticleMentions = (
  feedItem: Partial<FeedType>,
  termProminence: CategoryFilter,
) => {
  const { id, prominence } = feedItem;
  if (!id || !prominence?.length) return;

  if (termProminence) {
    for (const term of prominence) {
      const prettyProminence = getPrettyName(term);
      updateItemCount(termProminence, prettyProminence, id);
    }
  }
};

export const updateCountsForTopTopics = (
  feedItem: Partial<FeedType>,
  topTopics: CategoryFilter,
) => {
  const { id, knownTags: feedKnownTags } = feedItem;
  if (!id || !feedKnownTags) return;

  if (!topTopics) return;

  for (const tag of feedKnownTags) {
    updateItemCount(topTopics, tag, id);
  }
};

export const updateCountsForDomainAuthority = (
  feedItem: Partial<FeedType>,
  domainAuthorityFilter: CategoryFilter,
) => {
  const { id, maxDomainAuthority: authority } = feedItem;
  if (!id || authority === undefined || authority === null) return;

  if (!domainAuthorityFilter) return;

  const authorityLevel = getRangeLevel(authority, 10);
  updateItemCount(domainAuthorityFilter, authorityLevel, id);
};

export const updateCountsForQualityScore = (
  feedItem: Partial<FeedType>,
  qualityScoreFilter: CategoryFilter,
) => {
  const { id, maxScore: score } = feedItem;
  if (!id || score === undefined || score === null) return;

  if (!qualityScoreFilter) return;

  const scoreLevel = getQualityScoreRange(score);
  updateItemCount(qualityScoreFilter, scoreLevel, id);
};

export const updateCountsForSocialEngagement = (
  feedItem: Partial<FeedType>,
  socialEngagementFilter: CategoryFilter,
) => {
  const { maxSocial: engagement, id } = feedItem;
  if (engagement === undefined || engagement === null) return;

  if (!socialEngagementFilter || !id) return;

  const engagementLevels = [
    { min: 10000, label: "10,000+" },
    { min: 5000, label: "5,000-9,999" },
    { min: 3000, label: "3,000-4,999" },
    { min: 1000, label: "1,000-2,999" },
    { min: 500, label: "500-999" },
    { min: 100, label: "100-499" },
    { min: 1, label: "1-99" },
    { min: 0, label: "0" },
  ];

  const engagementLevel =
    engagementLevels.find((level) => engagement >= level.min)?.label || "0";

  updateItemCount(socialEngagementFilter, engagementLevel, id);
};

export const updateCountsForReadership = (
  feedItem: Partial<FeedType>,
  readershipFilter: CategoryFilter,
) => {
  const { articleReadership, id } = feedItem;
  if (articleReadership === undefined || articleReadership === null) return;

  if (!readershipFilter || !id) return;

  const redershipRanges = [
    { label: "1-10,000", min: 1, max: 10000 },
    { label: "10,001-50,000", min: 10001, max: 50000 },
    { label: "50,001-100,000", min: 50001, max: 100000 },
    { label: "100,001-250,000", min: 100001, max: 250000 },
    { label: "250,001-500,000", min: 250001, max: 500000 },
    { label: "500,001-1,000,000", min: 500001, max: 1000000 },
    { label: "1,000,001-5,000,000", min: 1000001, max: 5000000 },
    { label: "5,000,001-10,000,000", min: 5000001, max: 10000000 },
    { label: "10,000,001+", min: 10000001 },
  ];

  const readershipRangeLevel =
    redershipRanges.find(
      (r) =>
        ((r.max && articleReadership <= r.max) || !r.max) &&
        articleReadership >= r.min,
    )?.label || "0";

  updateItemCount(readershipFilter, readershipRangeLevel, id);
};

const getRangeLevel = (value: number, increment = 10) => {
  if (value >= 90) {
    return "90-100";
  }

  const lowerBound = Math.floor(value / increment) * increment;
  return `${lowerBound}-${lowerBound + increment - 1}`;
};

const getQualityScoreRange = (value: number): string => {
  if (value >= 80) return "80 to 100";
  if (value >= 60) return "60 to 79";
  if (value >= 40) return "40 to 59";
  if (value >= 20) return "20 to 39";
  if (value >= 0) return "0 to 19";
  if (value >= -19) return "-1 to -19";
  if (value >= -39) return "-20 to -39";
  if (value >= -59) return "-40 to -59";
  if (value >= -79) return "-60 to -79";
  return "-80 to -100";
};

type PartialFeedTag = {
  tagKind: string;
  tag: string;
  feedArticleIds: number[];
};

export const setCountsForThemes = (
  tags: PartialFeedTag[],
  categoryAppliedFilters: CategoryAppliedFilter,
) => {
  for (const tag of tags) {
    const tagKind = getPrettyName(tag.tagKind);
    let categoryFilter = categoryAppliedFilters[tagKind];

    if (!categoryFilter) {
      categoryFilter = {
        relationship: "OR",
        selected: [],
        itemCounts: {},
      };
      categoryAppliedFilters[tagKind] = categoryFilter;
    }

    if (!categoryFilter.itemCounts) return;

    if (!categoryFilter.itemCounts[tag.tag]) {
      categoryFilter.itemCounts[tag.tag] = [];
    }

    categoryFilter.itemCounts[tag.tag]?.push(...tag.feedArticleIds);
  }
};

export function calculateCounts(
  updatedCategoryAppliedFilters: CategoryAppliedFilter,
  filteredFeedItems: Partial<FeedWithAugTypes>[],
) {
  for (const feedItem of filteredFeedItems) {
    for (const key in updatedCategoryAppliedFilters) {
      const filter = updatedCategoryAppliedFilters[key];
      if (!filter) continue;
      if (!filter.itemCounts) filter.itemCounts = {};

      switch (key) {
        case FilterCategory.PROMINENCE:
          updateCountsForArticleMentions(feedItem, filter);
          break;
        case FilterCategory.PUBLICATION_TIER:
          updateCountsForCoverageTiers(feedItem, filter);
          break;
        case FilterCategory.SENTIMENT:
          updateCountsForSentiment(feedItem, filter);
          break;
        case FilterCategory.PUBLISHER:
          updateCountsForPublisher(feedItem, filter);
          break;
        case FilterCategory.AUTHOR:
          updateCountsForAuthors(feedItem, filter);
          break;
        case FilterCategory.CATEGORY:
          updateCountsForCategory(feedItem, filter);
          break;
        case FilterCategory.TOP_TOPICS:
          updateCountsForTopTopics(feedItem, filter);
          break;
        case FilterCategory.DOMAIN_AUTHORITY:
          updateCountsForDomainAuthority(feedItem, filter);
          break;
        case FilterCategory.IMPACT_SCORE:
          updateCountsForQualityScore(feedItem, filter);
          break;
        case FilterCategory.SOCIAL:
          updateCountsForSocialEngagement(feedItem, filter);
          break;
        case FilterCategory.READERSHIP:
          updateCountsForReadership(feedItem, filter);
          break;
        default:
          break;
      }
    }
  }
}

export function updateAppliedFiltersToUrl(
  categoryAppliedFilters: CategoryAppliedFilter,
) {
  const appliedFilters = Object.entries(categoryAppliedFilters).reduce(
    (acc: string[], [key, value]) => {
      if (value.selected && value.selected.length > 0) {
        acc.push(`${key}:${value.selected.join(",")}`);
      }
      return acc;
    },
    [],
  );
  setFilterParamsToUrl(appliedFilters.join(";"));
}

export const updateItemCount = (
  filter: CategoryAppliedFilter[keyof CategoryAppliedFilter] | undefined,
  key: string,
  id: number,
) => {
  if (!filter || !key) return;
  if (!filter.itemCounts) filter.itemCounts = {};
  if (!filter.itemCounts[key]) filter.itemCounts[key] = [];
  filter.itemCounts[key].push(id);
};

/**
 * Return the period range type based on the start and end date
 *
 * @returns {string} - period range day | week | month
 * @param startDate
 * @param endDate
 * @param days
 */
export const getDateRangeType = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  days: number | undefined,
): "day" | "week" | "month" | null => {
  let daysDifference = days;

  if (startDate && endDate) {
    daysDifference = differenceInDays(endDate, startDate);
  }

  if (daysDifference !== undefined) {
    if (daysDifference <= 31) {
      return "day";
    }

    if (daysDifference < 90) {
      return "week";
    }

    if (daysDifference >= 90) {
      return "month";
    }
  }

  return null;
};

/**
 * Checks if the feed item's date matches the feed volume selected date.
 *
 * @param itemDate - The date from the item (ISO string)
 * @param startOfPeriod - The selected date (Date object)
 * @param endOfPeriod - The selected date (Date object)
 * @returns {boolean} True if the dates match, false otherwise
 */
export const isWithinRange = (
  itemDate: string | undefined | null,
  startOfPeriod: string | null,
  endOfPeriod: string | null,
): boolean => {
  if (!itemDate || !startOfPeriod) {
    return false;
  }

  const parsedItemDate = toZonedTime(parseISO(itemDate), "UTC");
  const parsedStartDate = toZonedTime(parseISO(startOfPeriod), "UTC");

  // If endOfPeriod is not provided, check if the dates are the same
  if (!endOfPeriod) {
    return isSameDay(parsedItemDate, parsedStartDate);
  }

  const parsedEndDate = toZonedTime(parseISO(endOfPeriod), "UTC");
  return isWithinInterval(parsedItemDate, {
    start: parsedStartDate,
    end: parsedEndDate,
  });
};

import type { DerivedFilterItem } from "@/store/feedSlice";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

export const MIN_ITEMS_FOR_SEARCH = 11;
export const ITEMS_TO_SHOW = 10;

export function useFilterState(
  filters: DerivedFilterItem[],
  filterByTags: (tags: string[]) => void,
  title: string,
) {
  const selectedFiltersRef = useRef<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isOpen, setIsOpen] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [displayAddTopic, setDisplayAddTopic] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const showSearch = filters.length > MIN_ITEMS_FOR_SEARCH;
  const isCategory = title.toLowerCase() === "content category";
  const isLocation = title.toLowerCase() === "location";

  useLayoutEffect(() => {
    selectedFiltersRef.current = new Set(
      filters.filter((option) => option.selected).map((option) => option.id),
    );
  }, [filters]);

  const handleFilterChange = useCallback(
    (filterItem: DerivedFilterItem) => {
      const wasSelected = selectedFiltersRef.current.has(filterItem.id);

      if (wasSelected) {
        selectedFiltersRef.current.delete(filterItem.id);
      } else {
        // If this is a category selection and there were no previous selections
        if (isCategory && selectedFiltersRef.current.size === 0) {
          selectedFiltersRef.current.add(filterItem.id);
        } else if (!isLocation || (isLocation && !wasSelected)) {
          // Allow selection if it's not a location filter, or if it is but wasn't previously selected
          selectedFiltersRef.current.add(filterItem.id);
        }
      }

      filterByTags(
        [...selectedFiltersRef.current].map(
          (id) => filters.find((filter) => filter.id === id)?.value ?? "",
        ),
      );
    },
    [filters, filterByTags, isCategory, isLocation],
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      setShowMore(false);
    },
    [],
  );

  const handleClearSearch = useCallback(() => {
    setSearchTerm("");
    setShowMore(false);
  }, []);

  const toggleOpen = useCallback(() => {
    setIsTransitioning(true);
    setIsOpen((prev) => !prev);
    // Reset showMore when closing
    if (isOpen) {
      setShowMore(false);
    }
  }, [isOpen]);

  const toggleShowMore = useCallback(() => {
    setShowMore((prev) => !prev);
  }, []);

  const toggleAddTopic = useCallback(() => {
    setDisplayAddTopic((prev) => !prev);
  }, []);

  return {
    searchTerm,
    isOpen,
    showMore,
    displayAddTopic,
    isTransitioning,
    showSearch,
    handleFilterChange,
    handleSearchChange,
    handleClearSearch,
    toggleOpen,
    toggleShowMore,
    toggleAddTopic,
    setIsTransitioning,
  };
}

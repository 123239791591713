import FeedArticleRemove from "@/components/news/article/FeedArticleRemove";
import FeedEmptyState from "@/components/news/feed/FeedEmptyState";
import FeedItem from "@/components/news/feed/FeedItem";
import { Button } from "@/components/ui/button";
import type { FeedWithAugTypes } from "@/data-access/news";
import { useFeature } from "@/lib/feature-management";
import useArticleDeletionStore from "@/store/articleDeleteSlice.ts";
import useFeedStore from "@/store/useFeedStore";
import { useEffect, useRef, useState } from "react";
import FeedFilterHeader from "./FeedFilterHeader";
import { FeedSearch } from "./FeedSearch";
import FeedVolumeGraph from "./FeedVolumeGraph/FeedVolumeGraph";

// @Icons
import { Trash } from "@phosphor-icons/react";

// @Molecules
import ActionBar from "@/components/ui/molecules/ActionBar";

// @Utils
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";
import type { FilterData } from "@/store/feedHelpers";

interface FeedContentProps {
  feedId: string;
  searchTerms: string;
  feedItems: Partial<FeedWithAugTypes>[];
  className?: string;
}

const ITEMS_PER_PAGE = 20;

const FeedContent: React.FC<FeedContentProps> = ({
  feedId,
  searchTerms,
  feedItems,
  className,
}) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<HTMLDivElement>(null);
  const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE);
  const [fetching, isEmptyFeed, removalArr, categoryAppliedFilters] =
    useFeedStore((state) => [
      state.fetchingFeed,
      state.feedItems.length === 0,
      state.removalArr,
      state.categoryAppliedFilters,
    ]);
  const [
    articleIdsToDelete,
    closeActionBar,
    deleteAllArticles,
    isDeleteModalOpen,
    setDeleteModalOpen,
    toggleDeleteAllArticles,
  ] = useArticleDeletionStore((state) => [
    state.articleIdsToDelete,
    state.closeActionBar,
    state.deleteAllArticles,
    state.isDeleteModalOpen,
    state.setDeleteModalOpen,
    state.toggleDeleteAllArticles,
  ]);
  const hasChartAccess = useFeature("enable-charts", false);

  useEffect(() => {
    if (mainRef.current) {
      const scrollToPosition = () => {
        const graphHeight =
          graphRef.current?.getBoundingClientRect().height || 0;
        const currentScrollTop = mainRef.current?.scrollTop || 0;
        const targetScrollTop = Math.min(currentScrollTop, graphHeight);
        mainRef.current?.scrollTo(0, targetScrollTop);
      };
      scrollToPosition();
    }
    setVisibleItems(ITEMS_PER_PAGE);
  }, [feedId, searchTerms, feedItems]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (!target) return;
        if (target.isIntersecting) {
          setVisibleItems((prev) =>
            Math.min(prev + ITEMS_PER_PAGE, feedItems.length),
          );
        }
      },
      {
        root: mainRef.current,
        threshold: 0.1,
      },
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [feedItems.length]);

  const handleCloseFeedArticleRemoveModal = () => {
    setDeleteModalOpen(false);
    closeActionBar();
  };

  const filterData: FilterData[] = Object.entries(categoryAppliedFilters).map(
    ([key, value]) => {
      const options = Object.entries(value.itemCounts ?? {});
      return {
        options,
        selectedOptions: value.selected ?? [],
        title: value.name ?? key,
        categoryKey: key,
      };
    },
  );

  const numberOfArticles = articleIdsToDelete.length;

  const filteredItems = feedItems
    .filter((item) => item?.id && !removalArr.includes(item.id))
    .slice(0, visibleItems);

  return (
    <div className={className}>
      {numberOfArticles ? (
        <ActionBar onClose={closeActionBar}>
          <div className="flex justify-between items-center w-full">
            <div className="text-sm ml-6">
              <span className="font-semibold pr-1">
                {addCommasToNumbers(numberOfArticles)}
              </span>
              <span>{pluralize(numberOfArticles, "article")} selected</span>
            </div>
            <Button
              variant="outline"
              onPressChange={() => setDeleteModalOpen(true)}
            >
              <Trash size={16} className="text-red-750" />
              <span className="ml-2">Delete</span>
            </Button>
          </div>
        </ActionBar>
      ) : null}

      <FeedArticleRemove
        isOpen={isDeleteModalOpen}
        onClose={handleCloseFeedArticleRemoveModal}
        numberOfItemsToDelete={numberOfArticles}
      />

      <div
        ref={mainRef}
        className="bg-white overflow-y-auto overflow-x-hidden h-[calc(100vh-80px)] min-w-[932px] margin-auto flex-grow"
      >
        <FeedFilterHeader filterData={filterData} />
        {hasChartAccess && (
          <div ref={graphRef}>
            <FeedVolumeGraph />
          </div>
        )}
        <FeedSearch
          allArticleIds={feedItems
            .map((item) => item.id)
            .filter((id) => id !== undefined)}
          articleIdsToDelete={articleIdsToDelete}
          deleteAllArticles={deleteAllArticles}
          toggleDeleteAllArticles={toggleDeleteAllArticles}
        />

        <div className="lg:max-w-[932px] lg:mx-auto flex flex-col gap-7 pb-7">
          {fetching ? (
            Array.from({ length: 4 }, (_, index) => (
              <FeedItem
                key={index}
                isLoading={true}
                id={index}
                setFeedArticleRemoveModalOpen={() => {}}
              />
            ))
          ) : feedItems?.length === 0 ? (
            <FeedEmptyState
              title={
                isEmptyFeed
                  ? "We're gathering your news"
                  : "No items match your filters"
              }
              body={
                isEmptyFeed
                  ? "We're searching billions of sources. Please check back soon."
                  : "Try adjusting your filters to find the news you're looking for."
              }
            />
          ) : (
            <>
              {filteredItems.map((item) => (
                <FeedItem
                  key={item.id}
                  setFeedArticleRemoveModalOpen={setDeleteModalOpen}
                  {...item}
                />
              ))}
              {visibleItems < feedItems.length && (
                <div ref={loaderRef} className="h-20" />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedContent;

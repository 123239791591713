import { Badge } from "@/components/ui/data-display/badge";
import { Label } from "@/components/ui/field";
import { EditButton } from "@/components/ui/molecules/EditButton";
import { Popover, PopoverDialog } from "@/components/ui/popover";
import { Radio, RadioGroup } from "@/components/ui/radio-group";
import type {
  FeedWithAugTypes,
  UpdateArticleMutationInputType,
} from "@/data-access/news";
import type { CategoryQueryResponse } from "@/data-access/news/categoryQuery";
import { cn } from "@/lib/utils";
import {
  getPrettyName,
  toLowerCase,
  toSnakeCase,
} from "@/lib/utils/prettyName";
import { useFilterGroups } from "@/store/useFeedStore";
import { useRef, useState } from "react";

type Props = Partial<FeedWithAugTypes> & {
  categories?: CategoryQueryResponse["categories"];
  className?: string;
  onSave?: (
    value: UpdateArticleMutationInputType,
  ) => Promise<boolean> | undefined;
};

const selectedFilterGroupKeys = ["CATEGORY"];

export const ArticleBadgeRow: React.FC<Props> = ({
  categories,
  prominence,
  articleNewsCategory,
  overriddenArticleNewsCategory,
  className,
  tierLevel,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [savedSelectedCategory, setSavedSelectedCategory] = useState<string>(
    getPrettyName(overriddenArticleNewsCategory ?? articleNewsCategory ?? ""),
  );
  const triggerRef = useRef(null);

  const filterGroups = useFilterGroups();

  const categoryFilterGroup = filterGroups.find((filterGroup) =>
    selectedFilterGroupKeys.includes(filterGroup.key),
  );

  const handleEditClick = () => {
    setIsEditing(true);
    setIsHovered(false);
  };

  const sortedProminences =
    prominence?.sort((a, b) => {
      const desiredOrder = [
        "headline",
        "feature",
        "lede",
        "passing_mention",
        "news",
      ];
      return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
    }) || [];

  const multipleBadges = sortedProminences.filter((p) =>
    ["headline", "feature", "lede", "passing_mention"].includes(
      p.toLowerCase(),
    ),
  );

  const singleBadge = [savedSelectedCategory];
  const tierBadge = tierLevel ? [tierLevel] : [];
  const badges: string[] = [
    ...singleBadge,
    ...tierBadge,
    ...multipleBadges,
  ].map((badge) => String(badge));

  const onOpenChange = async (opened: boolean) => {
    setIsEditing(opened);
    if (!opened && selectedCategory) {
      if (onSave) {
        const result = await onSave({
          category: toSnakeCase(selectedCategory),
        });
        if (result) setSavedSelectedCategory(selectedCategory);
      }
    }
  };

  const desiredCategoryOrder = [
    "news",
    "press_release",
    "stocks",
    "owned_media",
    "market_research",
    "coupons",
    "crime",
    "sports",
    "recipes",
  ];

  const filteredAndSortedCategories =
    categories
      ?.filter(([value]) =>
        desiredCategoryOrder.includes(toSnakeCase(value ?? "").toLowerCase()),
      )
      .sort((a, b) => {
        const aValue = toSnakeCase(a[1] ?? "").toLowerCase();
        const bValue = toSnakeCase(b[1] ?? "").toLowerCase();
        return (
          desiredCategoryOrder.indexOf(aValue) -
          desiredCategoryOrder.indexOf(bValue)
        );
      }) ?? [];

  return (
    <div
      className={cn(
        "space-y-1 relative group items-center flex -my-2 -mx-2.5 p-2.5 rounded",
        className,
        isHovered ? "bg-slate-200/20" : "",
      )}
    >
      <div className="inline-flex gap-2">
        {badges.map((badge) => (
          <Badge
            key={badge}
            status={false}
            variant="white"
            bordered={true}
            className="font-semibold p-1"
          >
            {getPrettyName(badge)}
          </Badge>
        ))}
      </div>
      {categories && (
        <>
          <EditButton
            ref={triggerRef}
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="top-2 right-1"
          />
          <Popover
            triggerRef={triggerRef}
            isOpen={isEditing}
            onOpenChange={onOpenChange}
            className="min-w-48"
          >
            <PopoverDialog className="min-w-56">
              {filteredAndSortedCategories && (
                <RadioGroup
                  defaultValue={toLowerCase(savedSelectedCategory ?? "")}
                  onChange={setSelectedCategory}
                >
                  <Label>{categoryFilterGroup?.title}</Label>
                  {filteredAndSortedCategories.map(([id, value]) => (
                    <Radio key={id} value={id ?? ""}>
                      {value}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            </PopoverDialog>
          </Popover>
        </>
      )}
    </div>
  );
};

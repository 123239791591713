import { Separator } from "@/components/ui/controls/separator";
import { TooltipProvider } from "@/components/ui/data-display/tooltip";

import Sparkle from "@/assets/svg/Sparkle.svg?react";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import type { ArticleSentimentType } from "@/data-access/news";
import { Gauge, Info } from "@phosphor-icons/react";
import type React from "react";
import { useState } from "react";
import ArticleSentimentPolarity from "./ArticleSentimentPolarity";
import SentimentTooltip from "./SentimentTooltip";

interface ScoreDisplayProps {
  impactScore: number | null | undefined;
  articleSentiment:
    | {
        polarity: string;
        rationale: string;
      }
    | null
    | undefined;
  overriddenArticleSentiment:
    | {
        polarity: string;
        rationale: string;
        intensity: number;
      }
    | null
    | undefined;
  staleScore?: boolean;
  onSentimentChange: (sentiment: ArticleSentimentType) => void;
}

const ScoreDisplay: React.FC<ScoreDisplayProps> = ({
  impactScore,
  articleSentiment,
  overriddenArticleSentiment,
  staleScore = false,
  onSentimentChange,
}) => {
  const polarity = (
    overriddenArticleSentiment?.polarity || articleSentiment?.polarity
  )?.toLowerCase();
  const [rationale, setRationale] = useState(
    overriddenArticleSentiment?.rationale || articleSentiment?.rationale,
  );
  const [isAISentiment, setIsAISentiment] = useState(
    (!overriddenArticleSentiment?.polarity && articleSentiment?.polarity) ||
      overriddenArticleSentiment?.polarity.toLowerCase() ===
        articleSentiment?.polarity,
  );

  return (
    <TooltipProvider>
      <div className="flex flex-col gap-2.5 pt-7 w-30">
        <div className="-mb-1">
          <div className="flex flex-row space-x-1 items-center">
            <div className="text-gray-900 text-xs leading-7 font-semibold">
              Impact Score
            </div>
            <SentimentTooltip
              icon={<Gauge className="w-4 h-4 text-violet-800" />}
              title="The Handraise Impact Score"
              content="This is a proprietary scoring system that is based on the article’s prominence (headline, feature, lede, or passing mention classification), readership, social engagement, domain authority, and sentiment."
            >
              <Info className="text-gray-600 hover:text-slate-800 focus:text-slate-800 active:text-violet-800" />
            </SentimentTooltip>
          </div>

          <div className="inline-flex space-x-1 text-violet-800 items-center">
            {staleScore ? (
              <>
                <Gauge className="w-6 h-6" />
                <Skeleton className="flex justify-center bg-fuschia-600 items-center w-11 h-11" />
              </>
            ) : (
              <SentimentTooltip
                icon={<Gauge className="w-4 h-4 text-violet-800" />}
                title={`Why is the Impact Score ${impactScore}?`}
                content="This is based on the article's prominence (headline, feature, lede, or passing mention classification), readership, social engagement, domain authority, and sentiment."
              >
                <Gauge className="w-6 h-6" />
                <p className="font-semibold text-base text-center">
                  {impactScore}
                </p>
              </SentimentTooltip>
            )}
          </div>
        </div>

        <Separator />
        <div>
          <div className="flex flex-row space-x-1 items-center">
            <div
              className="text-gray-900 text-xs leading-7 font-semibold"
              title={rationale}
            >
              {isAISentiment ? <>AI Sentiment</> : <>Sentiment</>}
            </div>
            {isAISentiment && <Sparkle />}
            {polarity && (
              <SentimentTooltip
                title={
                  <div className="flex items-center space-x-2 mb-0.5">
                    <h6 className="font-bold whitespace-nowrap leading-4">
                      What is AI Sentiment?
                    </h6>
                  </div>
                }
                content="Handraise's proprietary AI Sentiment technology looks specifically through the lens of your brand, as a human would, to gauge the emotional tone conveyed in a piece of content, whether positive, negative, or neutral. We assess sentiment by analyzing context and language patterns to determine how a reasonable person would interpret the emotions expressed. This allows us to measure public perception and reactions more accurately across media."
                icon={<Sparkle />}
              >
                <Info className="text-gray-600 hover:text-slate-800 focus:text-slate-800 active:text-violet-800" />
              </SentimentTooltip>
            )}
          </div>
          <ArticleSentimentPolarity
            articleSentiment={articleSentiment}
            overriddenArticleSentiment={overriddenArticleSentiment}
            onSentimentChange={async (sentiment) => {
              setRationale(sentiment?.rationale);
              setIsAISentiment(
                sentiment?.polarity === articleSentiment?.polarity,
              );
              await onSentimentChange(sentiment);
            }}
          />
        </div>
      </div>
    </TooltipProvider>
  );
};

export default ScoreDisplay;

import { Button } from "@/components/ui/button";
import { EditButton } from "@/components/ui/molecules/EditButton";
import PublisherSearchDropdown from "@/components/ui/molecules/PublisherSearchDropdown";
import { toast } from "@/components/ui/toast";
import type { ArticlePublisherType } from "@/data-access/news";
import { usePublisherSearch } from "@/hooks/usePublisherSearch";
import { cn } from "@/lib/utils";
import useFeedStore from "@/store/useFeedStore";
import { useRef, useState } from "react";
import EditableDate from "../shared/EditableDate";
import EditableField from "../shared/EditableField";
import { formattedDateToUTC } from "../shared/utils";

interface ArticleHeaderProps {
  articleId: number;
  headline: string;
  overriddenHeadline?: string;
  articlePublisher: ArticlePublisherType;
  overriddenArticlePublisher?: ArticlePublisherType;
  articleDate?: string | null;
  articleUrl?: string | null;
  handleHeadlineClick?: () => void;
  onHeadlineChange?: (newHeadline: string) => void;
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  articleId,
  headline,
  overriddenHeadline,
  overriddenArticlePublisher,
  articlePublisher,
  articleDate,
  articleUrl,
  handleHeadlineClick,
  onHeadlineChange,
}) => {
  const [currentPublisher, setCurrentPublisher] = useState(
    overriddenArticlePublisher ?? articlePublisher,
  );
  const [updateArticle] = useFeedStore((state) => [state.updateArticle]);
  const [editedHeadline, setEditedHeadline] = useState(
    overriddenHeadline ?? headline,
  );
  const [editedDate, setEditedDate] = useState(articleDate);

  const [isEditingPublisher, setIsEditingPublisher] = useState(false);
  const { publishers, isLoading, handleSearch } = usePublisherSearch();
  const [isHovered, setIsHovered] = useState(false);
  const triggerRef = useRef(null);

  const handleArticleOpen = () => {
    if (!articleUrl) return;
    window.open(articleUrl, "_blank", "noopener,noreferrer");
  };

  const handleHeadlineSave = (updatedHeadline: string) => {
    if (updatedHeadline.trim().length < 1) {
      return false;
    }

    onHeadlineChange?.(updatedHeadline);
    setEditedHeadline(updatedHeadline);
    return true;
  };

  const handleOnSaveDate = async (date: Date) => {
    const newDate = date.toISOString();
    if (newDate === articleDate) return;
    const result = await updateArticle({ articleId, datePublished: newDate });
    if (!result) {
      toast.error("Failed to update date.");
    } else {
      setEditedDate(newDate);
    }
  };

  const handlePublisherSelect = async (publisherId: string) => {
    const selectedPublisher = publishers.find((p) => p.id === publisherId);
    if (!selectedPublisher) return;
    const publisher = {
      id: selectedPublisher.id,
      name: selectedPublisher.name,
      url: selectedPublisher.url,
      logoUrl: selectedPublisher.logos[0]?.image?.url ?? "",
    };
    const result = await updateArticle({
      articleId,
      publisher,
    });
    if (result) {
      setIsEditingPublisher(false);
      setCurrentPublisher({
        ...publisher,
        id: +publisher.id,
      });
    } else {
      toast.error("Failed to update publisher");
    }
  };

  // Transform ArticlePublisherType to match NewsFeedPublisherType format
  const selectedPublishers = {
    selected: currentPublisher
      ? [
          {
            id: String(currentPublisher.id),
            name: currentPublisher.name,
            url: currentPublisher.url || "",
            logos: [
              {
                image: {
                  url: currentPublisher.logoUrl || "",
                },
              },
            ],
          },
        ]
      : [],
  };

  const handleEditClick = () => {
    setIsEditingPublisher(true);
    setIsHovered(false);
  };

  return (
    <div className="flex gap-y-2 flex-col relative -mt-1">
      <div>
        <EditableDate
          initialDate={formattedDateToUTC(editedDate)}
          onSave={handleOnSaveDate}
        />
        <EditableField
          fieldName="Headline"
          initialFieldValue={editedHeadline}
          onSave={handleHeadlineSave}
          maxLines={3}
          className="text-xl text-left font-serif pr-5 overflow-hidden leading-tight text-slate-700 line-clamp-3 underline-offset-2 hover:underline hover:cursor-pointer"
          nonHoverClick={handleHeadlineClick}
        />
      </div>
      {isEditingPublisher ? (
        <div className="w-[342px] -my-0.5 -mx-1">
          <PublisherSearchDropdown
            publishers={publishers}
            isLoading={isLoading}
            onSearch={handleSearch}
            onSelect={handlePublisherSelect}
            selectedPublishers={selectedPublishers}
            multiSelect={false}
            className="h-8"
            autoOpen={true}
            cancelEdit={() => setIsEditingPublisher(false)}
          />
        </div>
      ) : (
        <div
          className={cn(
            "space-y-1 relative group items-center w-fit -my-0.5 -mx-2 py-0.5 px-2 rounded pr-8",
            isHovered ? "bg-slate-200/20" : "",
          )}
        >
          <EditButton
            ref={triggerRef}
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="top-1.5 right-1"
          />
          <Button
            variant={"link"}
            className="justify-start p-0 m-0 h-5 space-x-1"
            onPress={handleArticleOpen}
          >
            {currentPublisher?.logoUrl && (
              <img
                src={currentPublisher.logoUrl}
                alt={currentPublisher.name}
                className="h-4 object-contain"
              />
            )}
            <p
              className="w-96 text-xs leading-tight font-normal text-blue-700 underline decoration-blue-500 hover:text-blue-800 hover:decoration-blue-800 transition-colors overflow-hidden whitespace-nowrap text-ellipsis"
              title={currentPublisher?.name || "Unknown Publisher"}
            >
              {currentPublisher?.name || "Unknown Publisher"}
            </p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ArticleHeader;

import type { DerivedFilterItem } from "@/store/feedSlice";
import { useMemo, useRef } from "react";

export function useFilterSorting(
  filters: DerivedFilterItem[],
  searchTerm: string,
  sorted: boolean,
  title: string,
) {
  const sortedFilterIdsRef = useRef<string[]>([]);

  return useMemo(() => {
    let result = filters;
    const anyItemSelected = filters.some((filter) => filter.selected);
    const hasPreviousSort = sortedFilterIdsRef.current.length > 0;

    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter((filter) =>
        filter.value.toLowerCase().includes(searchLower),
      );
    }

    // Only sort if no items are selected or we haven't sorted before
    if (sorted && (!anyItemSelected || !hasPreviousSort)) {
      result = [...result].sort((a, b) => {
        return b.count - a.count;
      });

      // Store the sorted order
      sortedFilterIdsRef.current = result.map((f) => f.id);
    }

    // If we have a previous sort order, maintain it
    if (hasPreviousSort) {
      result.sort(
        (a, b) =>
          sortedFilterIdsRef.current.indexOf(a.id) -
          sortedFilterIdsRef.current.indexOf(b.id),
      );
    }

    return result;
  }, [filters, searchTerm, sorted, title]);
}

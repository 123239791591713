import { graphql } from "../../graphql";
import { FeedFragment, FeedPageV2Fragment } from "../fragments/feed-fragments";
import { tierConfigurationFragment } from "../tier";

export const FeedOverviewQuery = graphql(
  `
    query FeedOverviewQuery {
      feeds {
        ...FeedFragment
        updatedAt
        jobLastRun
      }
    }
  `,
  [FeedFragment],
);

export const SideBarDataQuery = graphql(
  `
    query SideBarDataQuery {
      feeds {
        ...FeedFragment
        terms {
          term
          description
        }
        knownTags {
          tag
        }
      }
      campaigns {
        id
        name
      }
    }
  `,
  [FeedFragment],
);

export const NewsFeedsDataQuery = graphql(`
  query NewsFeedsDataQuery($dayFilter: Int) {
    newsfeedData(daysToFilter: $dayFilter) {
      id
      feedName
      jobLastRun
      feedItems {
        id
        articleId
        summary
        imageUrl
        headline
        maxScore
        maxSocial
        prominence
        storyArticleCount
        storyChartIds
        storyChartScores
        storyFirstArticlePublish
        itemType
        articleLastUpdateDate
        articleReadership
        maxDomainAuthority
        storyLastArticlePublish
        articleUrl
        articleNewsCategory
        storyPublishers {
          iconUrl
          name
        }
        articlePublisher {
          id
          name
          url
          logoUrl
        }
        articleMentions {
          snippet
          term
          location
        }
        storyPublishers {
          iconUrl
          name
        }
        discoveredTags {
          id
          tag
          tagKind
        }
        articleAuthors {
          id
          name
        }
      }
    }
  }
`);

export const NewsFeedV2Query = graphql(
  `
  query FeedQueryV2($feedId: ID!, $dayFilter: Int, $startDate: Date, $endDate: Date) {
    feedDataV2(filters: {daysToFilter: $dayFilter, feedId: $feedId, startDate: $startDate, endDate: $endDate}, pagination: null) {
      ...FeedPageV2Fragment
    }
    readershipConfiguration {
      ...TierConfig
      tenant {
        enableReadership
        }
      }
    tierConfiguration {
      ...TierConfig
      tenant {
        enableCustomTierScoring
      }
    }
    tier1Pub: publisherConfiguration(tier: TIER_ONE) {
      __typename
      publishers {
          id
        }
    }
    tier2Pub: publisherConfiguration(tier: TIER_TWO) {
      __typename
      publishers {
          id
        }
    }
    tier3Pub: publisherConfiguration(tier: TIER_THREE) {
      __typename
      publishers {
          id
        }
    }
  }
`,
  [FeedPageV2Fragment, tierConfigurationFragment],
);

export const SingleFeedDataV2Query = graphql(
  `
    query singleFeedDataV2($feedArticleId: ID!, $feedId: ID!) {
      singleFeedDataV2(feedArticleId: $feedArticleId, feedId: $feedId) {
        ...FeedPageV2Fragment
      }
      readershipConfiguration {
        ...TierConfig
        tenant {
          enableReadership
          }
        }
      tierConfiguration {
        ...TierConfig
        tenant {
          enableCustomTierScoring
        }
      }
      tier1Pub: publisherConfiguration(tier: TIER_ONE) {
        __typename
        publishers {
            id
          }
      }
      tier2Pub: publisherConfiguration(tier: TIER_TWO) {
        __typename
        publishers {
            id
          }
      }
      tier3Pub: publisherConfiguration(tier: TIER_THREE) {
        __typename
        publishers {
            id
          }
      }
    }
  `,
  [FeedPageV2Fragment, tierConfigurationFragment],
);

export const FilterSidebarQuery = graphql(
  `
  query FeedFilterSidebar($feedId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
    precomputeCounts(
      filters: {feedId: $feedId, startDate: $startDate, endDate: $endDate}
    ) {
      counts {
        category
        items {
          key
          label
          count
        }
      }
    }
  }
  `,
);

import { graphql } from "@/data-access/graphql";
import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";

import { FilterCategory } from "@/store/feedHelpers";
// @Store
import useFeedStore from "@/store/useFeedStore.ts";

export const extractSelectedContentCategories = (
  filters: Record<string, any>,
) => {
  const contentCategory = filters[FilterCategory.CATEGORY];
  if (
    contentCategory &&
    Array.isArray(contentCategory.selected) &&
    contentCategory.selected.length > 0
  ) {
    return contentCategory.selected.map((item: string) =>
      item.trim().replace(/\s+/g, "_").toUpperCase(),
    );
  }
  return null; // Return null if no selected items
};

export const GetFeedVolumeQuery = graphql(`
  query GetFeedVolume($input: VolumeDataInput!) {
    feedVolumeData(input: $input) {
      startOfPeriod
      endOfPeriod
      volume
    }
  }
`);

type GetFeedVolumeVariablesType = Parameters<
  Exclude<typeof GetFeedVolumeQuery.__apiType, undefined>
>[0];

type VolumeDataInputCategoriesType =
  GetFeedVolumeVariablesType["input"]["categories"];

export const useGetFeedVolume = () => {
  // url params
  const { feedId } = useParams();

  const [
    endDate,
    filterDays,
    startDate,
    isCustomDaysRange,
    period,
    categoryAppliedFilters,
  ] = useFeedStore((state) => [
    state.endDate,
    state.filterDays,
    state.startDate,
    state.isCustomDaysRange,
    state.period,
    state.categoryAppliedFilters,
  ]);

  const startDateFormatted = startDate ? format(startDate, "yyyy-MM-dd") : null;
  const endDateFormatted = endDate ? format(endDate, "yyyy-MM-dd") : null;
  const categories = extractSelectedContentCategories(categoryAppliedFilters);

  const variables: GetFeedVolumeVariablesType["input"] = {
    categories: categories as VolumeDataInputCategoriesType,
    // biome-ignore lint/style/noNonNullAssertion: feedId will always be a string
    feedId: Number.parseInt(feedId!),
    period,
    startDate: startDateFormatted,
    endDate: endDateFormatted,
    daysSince: isCustomDaysRange ? null : filterDays,
  };

  const [result] = useQuery({
    query: GetFeedVolumeQuery,
    variables: { input: variables },
  });

  const { data, fetching } = result;

  return {
    feedVolume: data?.feedVolumeData,
    fetching,
  };
};

export type Period = "day" | "week" | "month" | null;

type FormatterOptions = {
  startDate: string | undefined;
  endDate: string | undefined;
};

export const getFormatterForPeriod = (
  v: string | null,
  period: Period,
  { startDate, endDate }: FormatterOptions,
): string => {
  if (v && period && startDate && endDate) {
    const parseDate = (date: string) => toZonedTime(parseISO(date), "UTC");
    if (period === "day") {
      return format(parseDate(v), "MMM dd, yyyy");
    }

    if (period === "week") {
      const start = format(parseDate(startDate), "MMM d");
      const end = format(parseDate(endDate), "d, yyyy");
      return `${start}-${end}`;
    }

    if (period === "month") {
      return format(parseDate(startDate), "MMM yyyy");
    }
  }

  return "";
};

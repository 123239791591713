import { graphql } from "../../graphql";

export const FeedFragment = graphql(`
  fragment FeedFragment on FeedType @_unmask {
    id
    name
  }
`);

export const FeedPageV2Fragment = graphql(`
  fragment FeedPageV2Fragment on FeedPageV2 @_unmask {
    id
    articleId
    headline
    overriddenHeadline
    imageUrl
    maxScore
    articlePublisher {
      id
      logoUrl
      name
      url
    }
    overriddenArticlePublisher {
      id
      logoUrl
      name
      url
    }
    articleAuthors {
      id
      name
    }
    overriddenArticleAuthors {
      id
      name
    }
    overriddenDatePublished
    articleNewsCategory
    overriddenArticleNewsCategory
    articleReadership
    articleSentiment {
      polarity
      rationale
    }
    overriddenArticleSentiment {
      polarity
      rationale
      intensity
    }
    firstArticleMention {
      snippet
      term
      location
    }
    articleUrl
    datePublished
    feedId
    headline
    id
    imageUrl
    articleLastUpdateDate
    knownTags
    maxDomainAuthority
    maxScore
    prominence
    maxSocial
    sortDate
    summary
    overriddenSummary
    topics
  }
`);
